import { Component, OnInit, Input, ViewChild, ElementRef, Inject, AfterViewInit, Attribute, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { Photo, StatusType } from '../models/photo';
import { PhotoService } from '../services/photo.service';
import { Router } from '@angular/router';
import { Texte, TexteDisplayType, TexteShapeType, TexteType } from '../models/texte';
import { TexteService } from '../services/texte.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject, Subject, map } from 'rxjs';
import * as $ from 'jquery';
import { Page } from '../pagination';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { saveAs } from 'file-saver';
import { download } from 'downloadjs';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { ImageService } from '@/services/image.service';
// import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { NGXLogger } from 'ngx-logger';
import { event } from 'jquery';
import { createSpeakLine, createThoughtLine, computeArrowPoints, createEllipse, textvalue, setCarretPos, seteditable, createPhylactereRect, createPhylactereOvale, computeArrowPoints8, dataURLtoBlob, genImageWithText, genImageWithTextFast } from '../shared/util';
import { highlightTexte, unhighlightTexte } from '../shared/util';
import { updateThoughtLine, updateSpeakLine } from '../shared/util';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewTextDialogComponent } from './new-text-dialog/new-text-dialog.component';
import { TextTypeDialogComponent } from './text-type-dialog/text-type-dialog.component';
import { TextShapeDialogComponent } from './text-shape-dialog/text-shape-dialog.component';
import { BackgroundColorPickerDialogComponent } from './background-color-picker-dialog/background-color-picker-dialog.component';
import { DeleteTextDialogComponent } from './delete-text-dialog/delete-text-dialog.component';
import { counterIncrement } from 'html2canvas/dist/types/css/property-descriptors/counter-increment';
import { NeedTextSelectedDialogComponent } from './need-text-selected-dialog/need-text-selected-dialog.component';
import { logger } from '@azure/storage-blob';
import { UploadService } from '@/services/upload.service';
import { TelechargeComponent } from '@/telecharge/telecharge.component';
import { AuthenticationService } from '@/_services';
import { SharingDialogComponent } from '@/serie-detail/sharing-dialog/sharing-dialog.component';
import { DeletePhotoDialogComponent } from '@/delete-photo-dialog/delete-photo-dialog.component';
import { AppConfigService } from '@/services/app-config.service';
import { LanguageService } from '@/services/language.service';

@Component({
    selector: 'app-edit-photo',
    templateUrl: './edit-photo.component.html',
    styleUrls: ['./edit-photo.component.scss'],
    standalone: false
})
export class EditPhotoComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('myImg', { static: false }) myImg: ElementRef;
  // @ViewChild('mySvg', { static: false }) mySvg: ElementRef;
  @Input() photo: Photo;
  photo$: Observable<Photo>;
  // edit, noedit, photoshoot   'editphotoshoot'

  episodeMode: string;


  texteForm: FormGroup;
  photo_id: number;

  selectedTextId: string;
  selectedTextElem: HTMLElement;
  selectedTextText: Texte;
  selectedTextEditable: boolean;
  selectedTextNewline: boolean;

  deleted_text_id: string;

  textes$: Observable<Texte[]>;
  private _textes: BehaviorSubject<Texte[]>;

  private loaded: Boolean;
  private loaded$: Subject<Boolean>;

  // private svg;
  private divimgratio: number;
  private imgratio: number;
  private imgratio_x: number;
  private imgratio_y: number;
  private imgtopleft_x: number = 0;
  private imgtopleft_y: number = 0;

  display_comment: boolean = true;
  display_comments = false;
  islikedbyme: boolean;
  iscommentedbyme: boolean;
  nblikes: number;
  nbcomments: number;
  isbookmarkedbyme$: Observable<boolean>
  isbookmarkedbyme: boolean
  nbbookmarks: number;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appconfigService: AppConfigService,
    public authenticationService: AuthenticationService,
    private texteService: TexteService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private photoService: PhotoService,
    private imageService: ImageService,
    private uploadService: UploadService,
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: {},
    private logger: NGXLogger
  ) {
    this.logger.debug('constructor edit-photo-component  ');
    // suposed to suppress scrolling  : does not work
    // document.body.style.overflow = 'hidden';

    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.logger.debug('constructor edit-photo-component NavigationStart popstate detected ');
          // this.back();
          this.photoService.changeFullscreen(false);
        }
      });
  }

  ngOnInit(): void {
    this.episodeMode = this.route.snapshot.paramMap.get('episodeMode');
    this.logger.debug('EditPhotoComponent episodeMode : ' + this.episodeMode);
    // document.body.style.overflow = 'hidden';
    // this._textes = <BehaviorSubject<Texte[]>>new BehaviorSubject([]);
    // document.execCommand('defaultParagraphSeparator', false, 'p');
    this.photoService.changeFullscreen(true);
    this._textes = <BehaviorSubject<Texte[]>>new BehaviorSubject([]);
    this.textes$ = this._textes.asObservable();
    this.photo_id = +this.route.snapshot.paramMap.get('id');

    this.logger.debug('EditPhotoComponent  photo_id: ', this.photo_id);
    this.loaded = false;
    this.loaded$ = <Subject<Boolean>>new ReplaySubject();

    if (this.photo_id != 0) {
      this.refreshPhoto();
    }
    if (this.episodeMode == 'photoshoot')
      this.newPhotoDialog();
  }

  refreshPhoto() {

    this.photo$ = this.getPhoto(this.photo_id);
    this.photo$.subscribe(photo => {
      this.logger.debug('EditPhotoComponent photo$ subscribed: ');
      this.photo = photo;

      this.display_comments = false
      this.photoService.islikedbyme(this.photo.id).subscribe(data => this.islikedbyme = data);
      this.photoService.iscommentedbyme(this.photo.id).subscribe(data => this.iscommentedbyme = data);
      this.photoService.nblikes(this.photo.id).subscribe(data => this.nblikes = data);
      this.photoService.nbcomments(this.photo.id).subscribe(data => this.nbcomments = data);


      this.texteService.getPhotoTextes(this.photo_id).subscribe(txts => {
        this.logger.debug('EditPhotoComponent get photo textes: ');
        this._textes.next(txts);
        this.loaded$.subscribe(x => {

          this.logger.debug('EditPhotoComponent image loaded : ');
          // this.imgratio = this.myImg.nativeElement.clientWidth / this.myImg.nativeElement.naturalWidth;
          this.divimgratio = this.myImg.nativeElement.clientWidth / this.myImg.nativeElement.clientHeight
          this.imgratio = photo.size_x / photo.size_y
          this.imgratio_x = this.myImg.nativeElement.clientWidth / photo.size_x;
          this.imgratio_y = this.myImg.nativeElement.clientHeight / photo.size_y;

          if (this.imgratio > this.divimgratio) {
            this.imgtopleft_x = 0;
            this.imgtopleft_y = Math.round((this.myImg.nativeElement.clientHeight - this.imgratio_x * photo.size_y) / 2);
          }
          else {
            this.imgtopleft_x = Math.round((this.myImg.nativeElement.clientWidth - this.imgratio_y * photo.size_x) / 2);
            this.imgtopleft_y = 0;
          }
          this.logger.debug('EditPhotoComponent imgtopleft: ' + this.imgtopleft_x + ' ' + this.imgtopleft_y);

          this.startdraggablesvg();
          this.textes$.subscribe(textes => {
            this.logger.debug('EditPhotoComponent texte$ subscribed : ');
            if (this.deleted_text_id && isPlatformBrowser(this.platformId)) {
              document.querySelectorAll(`[data-textid="${this.deleted_text_id}"]`).forEach(e => e.remove());
              this.deleted_text_id = null;
            }
            // for (const txt of page.results) {
            for (const txt of textes) {
              this.logger.debug('EditPhotoComponent txt: ', txt.texte);
              this.drawTexte(this.photo_id, photo.size_x, photo.size_y, txt);





              // htmlToImage.toPng(node3)
              //   .then(function (dataUrl) {
              //     let img = new Image();
              //     img.src = dataUrl;
              //     document.body.appendChild(img);
              //   })
              //   .catch(function (error) {
              //     console.error('oops, something went wrong!', error);
              //   });

              // htmlToImage.toBlob(document.getElementById('divimage'))
              //   .then(function (blob) {
              //     window.saveAs(blob, 'divimage.png');
              //   });
              // htmlToImage.toPng(document.getElementById('divimage'))
              //   .then(function (dataUrl) {
              //     download(dataUrl, 'divimage.png');
              //   });
              // }
            }
          });
          // let node = document.getElementById('divimage');
          // let node = document.querySelector("#divimage") as HTMLElement;
          // html2canvas(node).then(function (canvas) {
          //   document.body.appendChild(canvas);
          // });


          // let node2 = document.getElementById('divimage');
          // // let element = document.querySelector("#capture");
          // html2canvas(node2).then(function (canvas) {
          //   // Convert the canvas to blob
          //   canvas.toBlob(function (blob) {
          //     // To download directly on browser default 'downloads' location
          //     let link = document.createElement("a");
          //     link.download = "image.png";
          //     link.href = URL.createObjectURL(blob);
          //     link.click();
          //     // To save manually somewhere in file explorer
          //     // window.saveAs(blob, 'image.png');
          //   }, 'image/png');
          // });

          // ça marche
          if (isPlatformBrowser(this.platformId)) {
            let node3 = document.getElementById('divimage');
            genImageWithTextFast(this, node3, photo.numero + '.png', 'tiktok')
          }
          // let node3 = document.getElementById('divimage');
          // domtoimage.toPng(node3)
          //   .then(function (dataUrl) {
          //     var img = new Image();
          //     img.src = dataUrl;
          //     document.body.appendChild(img);
          //     const a = document.createElement('a');
          //     a.style.display = 'none';
          //     a.href = dataUrl;

          //     let blob = dataURLtoBlob(dataUrl);
          //     const file = new File(
          //       [blob],
          //       "blob.png"
          //       // ,
          //       // {
          //       //   type: "image/jpeg",
          //       //   lastModified: (new Date()).to()
          //       // }
          //     );
          //     let fileURL = this.uploadService.upload(file);
          //     console.log('upladed file' + fileURL);

          //     a.download = 'divimage.png';
          //     document.body.appendChild(a);
          //     a.click();
          //     document.body.removeChild(a)
          //   }.bind(this))
          //   .catch(function (error) {
          //     console.error('oops, something went wrong!', error);
          //   });


        });
      });
    });
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.body.style.removeProperty('overflow')
    }
  }
  ngAfterViewInit(): void {
    // let svg: SVGSVGElement;
    // svg = null;
    // svg = <SVGSVGElement><any>document.getElementById('svg' + this.photo_id);
    // this.makeDraggable(svg);
    // tslint:disable-next-line: deprecation
    if (isPlatformBrowser(this.platformId)) {
      document.execCommand('defaultParagraphSeparator', false, 'p');
    }
  }
  editPhoto() {
    this.logger.debug('editPhoto id : ' + this.photo.id)
    this.episodeMode = 'edit';
  }
  startdraggablesvg(): void {
    let svg: SVGSVGElement;
    svg = null;
    if (isPlatformBrowser(this.platformId)) {
      svg = <SVGSVGElement><any>document.getElementById('svg' + this.photo_id);
      this.makeDraggable(svg);
    }
  }
  // onSubmit() {
  // const formValue = this.texteForm.value;
  // const newTexte = new Texte();
  // console.log('you submitted value: ', formValue);
  // newTexte.texte = formValue['texte'];
  // newTexte.type = formValue['type'];
  // newTexte.photo = this.photo.id;

  // newTexte.position_x = 0;
  // newTexte.position_y = 0;

  // console.log('SerieFormComponent photourl: ', newTexte.texte);
  //  name = name.trim();
  // add texte to db

  // newTexte.id = this.add_parole(newTexte);
  //    const textDivId = 'texte' + Math.floor(Math.random() * 100);
  // this.texteService.addTexte(newTexte)
  //   .subscribe(txt => {
  //     this.textes.push(txt);
  //     // draw texte
  //     this.drawTexte(txt);

  //     //        this.textes.push({ id: 1, texte: 'azeaze', type: 1, photo_id: 13, position_left: 0, position_top: 3 });
  //     console.log('add_parole txt :' + txt.texte);
  //     console.log('add_parole id :' + txt.id);
  //     return txt.id;
  //   });
  // draw texte
  // this.drawTexte(newTexte);

  // let node = document.getElementById('divimage');
  // html2canvas(node).then(function (canvas) {
  //   document.body.appendChild(canvas);
  // });

  // let node2 = document.getElementById('divimage');
  // // let element = document.querySelector("#capture");
  // html2canvas(node2).then(function (canvas) {
  //   // Convert the canvas to blob
  //   canvas.toBlob(function (blob) {
  //     // To download directly on browser default 'downloads' location
  //     let link = document.createElement("a");
  //     link.download = "image.png";
  //     link.href = URL.createObjectURL(blob);
  //     link.click();

  //     // To save manually somewhere in file explorer
  //     window.saveAs(blob, 'image.png');

  //   }, 'image/png');
  // });

  // let node3 = document.getElementById('divimage');
  // domtoimage.toPng(node3)
  //   .then(function (dataUrl) {
  //     var img = new Image();
  //     img.src = dataUrl;
  //     document.body.appendChild(img);
  //   })
  //   .catch(function (error) {
  //     console.error('oops, something went wrong!', error);
  //   });

  // htmlToImage.toPng(node3)
  //   .then(function (dataUrl) {
  //     let img = new Image();
  //     img.src = dataUrl;
  //     document.body.appendChild(img);
  //   })
  //   .catch(function (error) {
  //     console.error('oops, something went wrong!', error);
  //   });

  // htmlToImage.toBlob(document.getElementById('divimage'))
  //   .then(function (blob) {
  //     window.saveAs(blob, 'divimage.png');
  //   });
  // htmlToImage.toPng(document.getElementById('divimage'))
  //   .then(function (dataUrl) {
  //     download(dataUrl, 'divimage.png');
  //   });
  // }

  getPhoto(photo_id: number): Observable<Photo> {
    return this.photoService.getPhoto(photo_id);
    //      .subscribe(photo => this.photo = photo);
  }
  // pb
  newtext(texte: Texte) {
    this.logger.debug('newtext :' + texte.texte);
    this._textes.value.push(texte);
    this._textes.next(this._textes.value);

    this.drawTexte(this.photo_id, this.photo.size_x, this.photo.size_y, texte);

    // const node = document.getElementById('divimage');
    // html2canvas(node).then(function (canvas) {
    //   document.body.appendChild(canvas);
    // });

    // let node3 = document.getElementById('divimage');
    // domtoimage.toPng(node3)
    //   .then(function (dataUrl) {
    //     var img = new Image();
    //     img.src = dataUrl;
    //     document.body.appendChild(img);
    //   })
    //   .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    //   })
  }

  imgSize() {
    if (this.selectedTextId) {
      this.logger.debug('win imgSize=');
      unhighlightTexte(this.selectedTextId);
    }
    const realWidth = this.myImg.nativeElement.naturalWidth;
    const realHeight = this.myImg.nativeElement.naturalHeight;
    this.logger.debug('natural width=' + realWidth + ', ' + 'natural height=' + realHeight);
    const width = this.myImg.nativeElement.clientWidth;
    const height = this.myImg.nativeElement.clientHeight;
    this.logger.debug('real width=' + width + ', ' + 'real height=' + height);
    const value = window.devicePixelRatio;
    this.logger.debug('devicepixelratio =' + value);
    const zoomf = Math.round((window.outerWidth / window.innerWidth) * 100);
    this.logger.debug('zoomratio  =' + zoomf);
  }

  validateText() {
    this.logger.debug('validateText = iselected text id ' + this.selectedTextId);
    if (this.selectedTextId) {
      // document.execCommand('defaultParagraphSeparator', false, 'p');

      unhighlightTexte(this.selectedTextId);
      if (isPlatformBrowser(this.platformId)) {
        const textDiv = document.getElementById(this.selectedTextId);

        let newValue = '';
        let isOnFreshLine = true;
        let firstDiv = true;

        let newtextvalue = textvalue(textDiv)
        console.log('validateText textvalue : ' + newtextvalue);

        let childNodes = textDiv.childNodes;
        // Recursive function to navigate childNodes and build linebreaks with text
        function parseChildNodesForValueAndLines(childNodes: NodeListOf<ChildNode>) {
          console.log('parseChildNodesForValueAndLines called')
          for (let i = 0; i < childNodes.length; i++) {
            const childNode = childNodes[i];

            if (childNode.nodeName === 'BR') {
              console.log('parseChildNodesForValueAndLines BR')
              // BRs are always line breaks which means the next loop is on a fresh line
              newValue += '\n';
              isOnFreshLine = true;
              continue;
            }

            // We may or may not need to create a new line
            // if (childNode.nodeName === 'DIV' && isOnFreshLine === false) {
            if (childNode.nodeName === 'DIV') {
              if (firstDiv === false) {
                console.log('parseChildNodesForValueAndLines DIV')
                // Divs create new lines for themselves if they aren't already on one
                newValue += '\n';
              }
              else {
                firstDiv = false;
              }
            }

            // We may or may not need to create a new line
            if (childNode.nodeName === 'P') {
              console.log('parseChildNodesForValueAndLines P')
              newValue += '\n';
            }
            // Whether we created a new line or not, we'll use it for this content so the next loop will not be on a fresh line:
            isOnFreshLine = false;

            // Add the text content if this is a text node:
            if (childNode.nodeType === 3 && childNode.textContent) {
              console.log('parseChildNodesForValueAndLines textcontent : ' + childNode.textContent)
              newValue += childNode.textContent;
            }

            // If this node has children, get into them as well:
            parseChildNodesForValueAndLines(childNode.childNodes);
          }
        }

        // If this node has children, get into them as well:
        parseChildNodesForValueAndLines(childNodes);


        const newTexte = new Texte();
        // newTexte.texte = textDiv.innerText;
        newTexte.texte = newValue;
        newTexte.photo = this.photo.id
        newTexte.id = parseInt(this.selectedTextId, 10);
        this.logger.debug('validateText photo_id : ' + this.photo.id + ' ' + this.photo.id);
        this.logger.debug('validateText innertext : ' + textDiv.innerText);
        this.logger.debug('validateText newValue : ' + newValue);
        this.logger.debug('validateText innerhtml : ' + textDiv.innerHTML);

        this.texteService.partialUpdateTexte(newTexte)
          .subscribe(txt => {
            this.logger.debug('validate text ' + JSON.stringify(txt));
          });
        var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, texte: newValue } : el);
        // var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, texte: textDiv.innerText } : el);
        this._textes.next(result);
        let item1 = result.find(i => i.id.toString() === this.selectedTextId);
        this.logger.debug('EditPhotoComponent item1 : ', JSON.stringify(item1));
        this.selectedTextId = null;
        // this._textes.value.push(texte);
        // this._textes.next(this._textes.value);

        //   .subscribe(txt => {
        //     this.newTexte.emit(txt)
        //   });
      }
    }
  }

  onImageLoad(evt) {
    if (evt && evt.target) {
      this.logger.debug('edit-photocomponent onImageLoad: ');
      const width = evt.target.naturalWidth;
      const height = evt.target.naturalHeight;
      const portrait = height > width ? true : false;
      this.logger.debug(width, height, 'portrait: ', portrait);
      this.loaded = true;
      this.loaded$.next(true);
    }
  }

  olddoTextSelected(id, clickedTextDiv) {
    this.logger.debug('EditPhotoComponent doTextSelected id :' + id);
    if (this.selectedTextId) {
      this.logger.debug('EditPhotoComponent doTextSelected this.selectedTextId  :' + this.selectedTextId);
    }
    if (this.selectedTextId == null) {
      this.selectedTextId = id;
      // text to see if cursor ok when no drag
      clickedTextDiv.onmousedown = null;
      clickedTextDiv.ontouchstart = null;
      highlightTexte(id, this);
    } else if (id !== this.selectedTextId) {

      // const seletedtext = document.getElementById(this.selectedTextId) as HTMLImageElement;;
      unhighlightTexte(this.selectedTextId);
      // text to see if cursor ok when no drag
      // seletedtext.onmousedown = dragMouseDown;
      // seletedtext.ontouchstart = dragMouseDown;
      highlightTexte(id, this);
      this.selectedTextId = id;
    } else { // same as already selected
      // unhighlightTexte(this.selectedTextId);
      // this.selectedTextId = null;
      this.logger.debug('EditPhotoComponent doTextSelected on same selectedtextid :' + this.selectedTextId);
    }
    this.logger.debug('EditPhotoComponent doTextSelected selectedtextid :' + this.selectedTextId);
  }


  oldtextClicked(e: Event) {
    let clickedTextDiv: HTMLDivElement;
    let parentTextDiv: HTMLDivElement;
    let selectedTextId: string;

    console.log('textClick');
    clickedTextDiv = e.target as HTMLDivElement;

    console.log('EditPhotoComponent textclicked id :' + clickedTextDiv.id);
    this.olddoTextSelected(clickedTextDiv.id, clickedTextDiv);
    return;
    // should store text id in the dom
    console.log('EditPhotoComponent textclicked id :' + clickedTextDiv.id);
    parentTextDiv = clickedTextDiv.parentNode as HTMLDivElement;
    console.log('EditPhotoComponent textclicked parent id :' + parentTextDiv.id);
    console.log('EditPhotoComponen))t textclicked parent selectedtextid i :' + parentTextDiv.dataset.selectedtextid);
    selectedTextId = parentTextDiv.dataset.selectedtextid;
    if (selectedTextId) {
      unhighlightTexte(selectedTextId);
    }
    if ((selectedTextId == null) || (clickedTextDiv.id !== selectedTextId)) {
      parentTextDiv.setAttribute('data-selectedtextid', clickedTextDiv.id);
      // should also unhilight previous text
      highlightTexte(clickedTextDiv.id, this);
    } else if ((selectedTextId) || (clickedTextDiv.id === selectedTextId)) {
      parentTextDiv.removeAttribute('data-selectedtextid');
    }
  }

  // setTextePos(posx: number, posy: number) {
  //   this.logger.debug('setTextePos x y  :' + posx + ' ' + posy);

  //   this.selectedText.position_x = posx;
  //   this.selectedText.position_y = posy;

  //   this.texteService.updateTexte(this.selectedText)
  //     .subscribe(txt => {
  //       this.logger.debug('setTextePos done x y  :' + posx + ' ' + posy);
  //     });
  // }


  // createSVG(id) {
  //   const svg = document.createElementNS('http://www.w3.org/2000/svg',
  //     'svg');
  //   svg.setAttribute('id', 'svg' + id);
  //   svg.setAttribute('style', 'position:absolute;top:0px;left:0px');
  //   svg.setAttribute('width', String(this.myImg.nativeElement.clientWidth));
  //   svg.setAttribute('height', String(this.myImg.nativeElement.clientHeight));
  //   svg.style.zIndex = '9';
  //   svg.setAttributeNS("http://www.w3.org/2000/xmlns/",
  //     "xmlns:xlink",
  //     "http://www.w3.org/1999/xlink");
  //   // document.body.appendChild(svg);
  //   return svg;
  // }

  drawTexte(photoid: number, size_x: number, size_y: number, txt: Texte) {
    const logger = this.logger;
    let svg: SVGSVGElement;
    let imgratio_x: number;
    let imgratio_y: number;
    let divimgratio: number;
    let imgratio: number;
    let textDivtop, textDivleft: number;
    let boundaryX2, boundaryY2: number;
    let imgtopleft_x: number = 0;
    let imgtopleft_y: number = 0;

    this.logger.debug('drawTexte txt id  ' + txt.id);

    // const imgratio = this.imgratio;
    // this.logger.debug('imgratio =' + String(imgratio));


    const myImg = document.getElementById('myimg' + photoid) as HTMLImageElement;;
    // imgratio = myImg.clientWidth / myImg.naturalWidth;
    divimgratio = this.divimgratio
    imgratio = this.imgratio
    imgratio_x = myImg.clientWidth / size_x;
    imgratio_y = myImg.clientHeight / size_y;
    this.logger.debug('drawtext divimgratio =' + String(divimgratio));
    this.logger.debug('drawtext imgratio =' + String(imgratio));
    this.logger.debug('drawtext imgratio_x =' + String(imgratio_x));
    this.logger.debug('drawtext imgratio_y =' + String(imgratio_y));

    if (imgratio > divimgratio) {
      imgtopleft_x = 0; //ok
      imgtopleft_y = (myImg.clientHeight - imgratio_x * size_y) / 2
    }
    else {
      imgtopleft_x = (myImg.clientWidth - imgratio_y * size_x) / 2;
      imgtopleft_y = 0; // ok
    }
    // const imageDiv = document.getElementById('divimage');
    // create only if does not existo
    // create only if dotttes not exist
    // const textnode = document.getElementById('txt' + String(txt.id)) as HTMLImageElement;;
    // const textnode = document.getElementById(String(txt.id)) as HTMLImageElement;;
    // if (textnode) textnode.remove();


    const textImg = document.getElementById('textimg' + photoid) as HTMLImageElement;;
    document.querySelectorAll(`[data-textid="${txt.id}"]`).forEach(e => e.remove());

    const textDiv = document.createElement('div');
    textImg.appendChild(textDiv);
    const textContent = document.createTextNode(txt.texte);
    textDiv.appendChild(textContent);
    textDiv.setAttribute('draggable', 'false');
    textDiv.setAttribute('data-textid', String(txt.id));
    textDiv.id = String(txt.id);
    textDiv.className = 'text-block';
    // textDiv.setAttribute('display', 'inline-block');
    textDiv.style.display = 'inline-block';

    // textDiv.style.backgroundColor = 'rgba(255,248,220,0.2)';
    // textDiv.style.backgroundColor = 'Cornsilk';
    textDiv.style.backgroundColor = txt.background_color;
    textDiv.style.fontSize = txt.font_size;
    // shoud be editable before selected and highlighted ?
    // textDiv.contentEditable = 'true';
    textDiv.style.whiteSpace = 'pre';
    textDiv.style.zIndex = '10';
    textDiv.style.position = 'absolute';
    textDiv.style.borderRadius = '7px';
    textDiv.style.padding = '7px';
    textDiv.style.border = '0px solid';
    this.logger.debug('drawTexte textDiv id  ' + textDiv.id);
    this.logger.debug('drawTexte textDiv pos  top: ' + textDiv.style.top + ' , left: ' + textDiv.style.left);




    if (imgratio > divimgratio) {
      textDivtop = Math.floor(txt.position_y * imgratio_x);
      textDivleft = Math.floor(txt.position_x * imgratio_x);
    }
    else {
      textDivtop = Math.floor(txt.position_y * imgratio_y);
      textDivleft = Math.floor(txt.position_x * imgratio_y);
    }
    textDivtop += imgtopleft_y;
    textDivleft += imgtopleft_x;


    this.logger.debug(' textdiv top =' + textDivtop);
    this.logger.debug(' teYxtdiv left =' + textDivleft);
    let bbox = textDiv.getBoundingClientRect();
    this.logger.debug('drawTexte textDiv bbox : ' + JSON.stringify(bbox));
    const PADDING = 0;
    const boundaryX1 = 0 + PADDING;
    const boundaryY1 = 0 + PADDING;
    if (imgratio < divimgratio) {
      boundaryX2 = size_x * imgratio_y - PADDING;
      boundaryY2 = myImg.clientHeight - PADDING;
    } else {
      boundaryX2 = myImg.clientWidth - PADDING;
      boundaryY2 = size_y * imgratio_x - PADDING;
    }
    this.logger.debug('drawTexte boundary x2 y2 : ' + boundaryX2 + ' ' + boundaryY2);
    let minX = boundaryX1;
    let maxX = boundaryX2 - bbox.width;
    let minY = boundaryY1;
    let maxY = boundaryY2 - bbox.height;
    minX += imgtopleft_x;
    maxX += imgtopleft_x;
    minY += imgtopleft_y;
    maxY += imgtopleft_y;

    logger.debug('minX maxX miny maxY ' +
      minX + ' ' + maxX + ' ' + minY + ' ' + maxY);
    // let dy = textDiv.offsetTop;
    // let dx = textDiv.offsetLeft;
    let dy = textDivtop;
    let dx = textDivleft;
    if (dx < minX) {
      dx = minX;
      // logger.debug('dx constrained ' + dx);
    } else if (dx > maxX) {
      dx = maxX;
      // logger.debug('dx constrained ' + dx);
    }
    if (dy < minY) {
      dy = minY;
      // logger.debug('dy constrained ' + dy);
    } else if (dy > maxY) {
      dy = maxY;
      // logger.debug('dy constrained ' + dy);
    }
    logger.debug('dx dy last ' + dx + ' ' + dy);
    textDiv.style.top = String(dy) + 'px';
    textDiv.style.left = String(dx) + 'px';

    // textDiv.style.top = String(Math.floor(txt.position_y * imgratio)) + 'px';
    // textDiv.style.left = String(Math.floor(txt.position_x * imgratio)) + 'px';

    this.logger.debug(' textdiv top =' + textDiv.style.top);
    this.logger.debug(' teYxtdiv left =' + textDiv.style.left);
    // removed to fix svg drag
    this.makeDragElement(textDiv, txt);
    // textDiv.addEventListener('click', this.textClicked.bind(this));

    // const dStrRight =
    //   'M' +
    //   textDiv.style.left.substring(0, textDiv.style.left.length - 2) + ',' +
    //   textDiv.style.top.substring(0, textDiv.style.top.length - 2) + ' ' +
    //   'L' + txt.arrow_x + ',' + txt.arrow_y;
    // if (this.svg == null) {
    //   this.svg = this.createSVG(txt.id);
    //   this.makeDraggable(this.svg);
    // }
    svg = null;
    svg = <SVGSVGElement><any>document.getElementById('svg' + photoid);
    // this.makeDraggable(svg);
    // imageDiv.appendChild(svg);

    let startxl = txt.arrow_x * imgratio;
    let startyl = txt.arrow_y * imgratio;
    if (imgratio > divimgratio) {
      startxl = txt.arrow_x * imgratio_x;
      startyl = txt.arrow_y * imgratio_x;
    }
    else {
      startxl = txt.arrow_x * imgratio_y;
      startyl = txt.arrow_y * imgratio_y;
    }
    startxl += imgtopleft_x;
    startyl += imgtopleft_y;

    // const tboxx = parseInt(textDiv.style.left.substring(0, textDiv.style.left.length - 2), 10);
    // const tboxy = parseInt(textDiv.style.top.substring(0, textDiv.style.top.length - 2), 10);
    // const startxl = txt.arrow_x * imgratio;
    // const startyl = txt.arrow_y * imgratio;
    logger.debug('startxl yl :' + startxl + ' , ' + startyl);
    // const startxl = parseInt(String(txt.arrow_x * imgratio), 10);
    // const startyl = parseInt(String(txt.arrow_y * imgratio), 10);
    // const tbox = textDiv.getBoundingClientRect();
    const arrowPoints = computeArrowPoints8(logger, textDiv.offsetLeft, textDiv.offsetTop,
      textDiv.offsetWidth, textDiv.offsetHeight,
      startxl, startyl);
    // txt.arrow_x * imgratio, txt.arrow_y * imgratio);
    // const arrowPoints = computeArrowPoints(tboxx, tboxy, tbox.width, tbox.height,
    // txt.arrow_x * imgratio, txt.arrow_y * imgratio);
    logger.debug('arrow points :' + JSON.stringify(arrowPoints));

    // delete speak or thought line
    // const textnode = document.getElementById('txt' + String(txt.id)) as HTMLImageElement;;
    // const speakline = document.getElementById('triangle'  +String(txt.id)) as HTMLImageElement;;
    // if (speakline) speakline.remove();
    // const speakline = document.getElementById('triangle'  +String(txt.id)) as HTMLImageElement;;
    // if (speakline) speakline.remove();

    // createSpeakLine(logger, svg, imgratio, txt.id, startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2);
    // createThoughtLine(logger, svg, imgratio, txt.id, startxl, startyl, arrowPoints.x1, arrowPoints.y1);
    // if (txt.type === TexteType.pensee) {
    //   createThoughtLine(logger, svg, imgratio, txt.id, txt.background_color, startxl, startyl, arrowPoints.x1, arrowPoints.y1);
    // } else if (txt.type === TexteType.parole) {
    //   createSpeakLine(logger, svg, imgratio, txt.id, txt.background_color, startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2);
    // } else if (txt.type === TexteType.texte) {
    //   // createSpeakLine(logger, svg, imgratio, txt.id, startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2);
    // }


    if (txt.display === TexteDisplayType.DISPLAY_BOTH || txt.display === TexteDisplayType.DISPLAY_ARROW) {
      if (txt.type === TexteType.pensee) {
        createThoughtLine(logger, svg, imgratio, txt.id, txt.background_color, startxl, startyl, arrowPoints.x1, arrowPoints.y1);
      }
    }
    //   } else if (txt.type === TexteType.parole) {
    //     createSpeakLine(logger, svg, imgratio, txt.id, txt.background_color, startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2);
    //   }
    // }

    // if (txt.display === TexteDisplayType.DISPLAY_BOTH || txt.display === TexteDisplayType.DISPLAY_BORDER) {
    //   if (txt.shape === TexteShapeType.ovale) {
    //     textDiv.style.border = '0px solid';
    //     textDiv.style.backgroundColor = 'rgba(255,255,255,0)';
    //     createEllipse(logger, svg, imgratio, txt.id, txt.background_color,
    //       textDiv.offsetLeft, textDiv.offsetTop, textDiv.offsetWidth, textDiv.offsetHeight);
    //   }
    //   else if (txt.shape === TexteShapeType.rectangle) {
    //     textDiv.style.backgroundColor = txt.background_color
    //     textDiv.style.border = '1px solid';
    //   }
    // } else {
    //   textDiv.style.border = '0px solid';
    // }

    textDiv.style.backgroundColor = 'rgba(255,255,255,0)';
    textDiv.style.border = '0px solid';


    if (txt.shape === TexteShapeType.ovale) {
      createPhylactereOvale(logger, svg, imgratio, txt.id, txt.background_color,
        startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2,
        textDiv.offsetLeft, textDiv.offsetTop, textDiv.offsetWidth, textDiv.offsetHeight, txt.type);
    }
    else if (txt.shape === TexteShapeType.rectangle) {
      createPhylactereRect(logger, svg, imgratio, txt.id, txt.background_color,
        startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2,
        textDiv.offsetLeft, textDiv.offsetTop, textDiv.offsetWidth, textDiv.offsetHeight, txt.type);
    }

    if ((txt.type === TexteType.pensee) || (txt.type === TexteType.parole)) {
      const line = document.createElementNS('http://www.w3.org/2000/svg',
        'line');
      line.setAttributeNS(null, 'x1', String(startxl));
      line.setAttributeNS(null, 'y1', String(startyl));
      line.setAttributeNS(null, 'x2', String(arrowPoints.x1));
      line.setAttributeNS(null, 'y2', String(arrowPoints.y1));
      // line.setAttributeNS(null, 'fill', 'none');
      line.setAttributeNS(null, 'stroke', 'blue');
      line.setAttribute('draggable', 'false');
      line.style.pointerEvents = 'all';
      line.style.position = 'relative';
      line.setAttribute('data-textid', String(txt.id));
      line.setAttribute('id', 'line' + String(txt.id));
      line.style.zIndex = '10';
      svg.appendChild(line);

      const circle = document.createElementNS('http://www.w3.org/2000/svg',
        'circle');
      circle.setAttribute('fill', 'blue'); // Note: NOT setAttributeNS()
      circle.setAttribute('fill', 'rgba(165,165,195,0.7)');
      // circle.setAttribute('cx', String(txt.arrow_x * imgratio));     // setAttribute turns 150 into a string
      // circle.setAttribute('cy', String(txt.arrow_y * imgratio));    // using a string works, too
      // circle.setAttribute('cx', String(arrowPoints.x1 * imgratio));     // setAttribute turns 150 into a string
      // circle.setAttribute('cy', String(arrowPoints.x2 * imgratio));    // using a string works, too
      circle.setAttribute('cx', String(startxl));     // setAttribute turns 150 into a string
      circle.setAttribute('cy', String(startyl));    // using a string works, too
      circle.setAttribute('r', '8');       // give the circle a radius so we can see it
      circle.setAttribute('draggable', 'false');
      circle.style.position = 'absolute';
      circle.style.zIndex = '11';
      circle.classList.add('draggable');
      circle.classList.add('confine');
      circle.setAttribute('data-textid', String(txt.id));
      circle.setAttribute('id', 'circle' + String(txt.id));
      svg.appendChild(circle);
    }




    if (this.selectedTextId && (+this.selectedTextId == txt.id))
      highlightTexte(this.selectedTextId, this)

    if (txt.carretpos) {
      logger.debug('txt.carretpos ' + txt.carretpos);
      setCarretPos(textDiv, txt.carretpos)
    }

    if (this.selectedTextId && this.selectedTextEditable && (+this.selectedTextId == txt.id))
      seteditable(this.selectedTextId, this)

    // function createThoughtLine(logger, svg, id, x1, y1, x2, y2) {
    //   const diam = 4;
    //   const nbstep = 5;
    //   logger.debug('createThoughtLine =' + String(imgratio));
    //   for (let i = 1; i < nbstep; i++) {
    //     logger.debug('creatYeThoughtLine nb ' + String(i));
    //     let ncircle = document.createElementNS('http://www.w3.org/2000/svg',
    //       'circle');
    //     ncircle.setAttribute('fill', 'Cornsilk'); // Note: NOT setAttributeNS()
    //     ncircle.setAttribute('cx', String(x1 + i * (x2 - x1) / nbstep));     // setAttribute turns 150 into a string
    //     ncircle.setAttribute('cy', String(y1 + i * (y2 - y1) / nbstep));    // using a string works, too
    //     ncircle.setAttribute('r', String(diam + i * 2));       // give the ncircle a radius so we can see it
    //     ncircle.setAttribute('draggable', 'false');
    //     ncircle.style.position = 'absolute';
    //     ncircle.style.zIndex = '10';
    //     // ncircle.classList.add('draggable');
    //     // ncircle.classList.add('confine');
    //     ncircle.setAttribute('data-textid', String(id));
    //     ncircle.setAttribute('id', 'ncirclet' + '-' + + String(i) + '-' + String(id));
    //     svg.appendChild(ncircle);
    //     logger.debug('createThoughtLine nb ' + String(i));
    //   }
    // }


    /*
        const arrowCanvas = document.createElement('canvas');
        // arrowCanvas.id = 'canvas' + Math.floor(Math.random() * 100);
        arrowCanvas.id = 'canvas' + String(txt.id);
        // rect = textDiv.getBoundingClientRect()
        // $("div").outerWidth()
        // var position = $("div").position();
        arrowCanvas.style.top = '0px';
        //  arrowCanvas.style.top = textDiv.offsetHeight.toString();
        console.log('drawTexte textDiv id  ' + arrowCanvas.style.top);
    
        // textDiv.offsetTop +
        arrowCanvas.style.left = '0px';
        // arrowCanvas.style.left = (textDiv.offsetWidth / 2).toString();
        // textDiv.offsetLeft +
        arrowCanvas.setAttribute('draggable', 'false');
        arrowCanvas.setAttribute('data-textid', String(txt.id));
    
        arrowCanvas.width = this.myImg.nativeElement.clientWidth;
        arrowCanvas.height = this.myImg.nativeElement.clientHeight;
        this.logger.debug(' arrowCanvas.width = ' + arrowCanvas.width);
        this.logger.debug(' arrowCanvas.height = ' + arrowCanvas.height);
        arrowCanvas.style.zIndex = '9';
        arrowCanvas.style.position = 'absolute';
        //    arrowCanvas.style.color = 'Cornsilk';
    
        // arrowCanvas.style.border = "1px solid";
        // imageDiv.appendChild(arrowCanvas);
        imageDiv.appendChild(arrowCanvas);
        // textDiv.appendChild(arrowCanvas);
    
    
        const ctx = arrowCanvas.getContext('2d');
        // ctx.fillStyle = "rgba(255, 0, 0, 0.2)";
        // ctx.fillRect(50, 50, 60, 80);
        // ctx.moveTo(0, 0);
        ctx.moveTo(parseInt(textDiv.style.left, 10), parseInt(textDiv.style.top, 10));
        ctx.lineTo(txt.arrow_x, txt.arrow_y);
        // ctx.lineTo(100, 150);
        ctx.strokeStyle = 'Red';
        ctx.stroke();
        //  this.add_parole($('#comment').val().toString());
     */
  }


  // makeDragElementbyid(id) {

  //   const textdiv = document.getElementById(id) as HTMLImageElement;;
  //   this.makeDragElement(textdiv, text);
  // }

  makeDragElement(elmnt, text: Texte) {
    const self = this;
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let selectedElement, offset, transform;
    let bbox, minX, maxX, minY, maxY, confined;
    const imgtopleft_x = this.imgtopleft_x;
    const imgtopleft_y = this.imgtopleft_y;
    const understestes = this._textes;
    // const txt: Textoe = understestes.value.find(elem => elmnt.target.id === elem.id);
    const txt: Texte = text;
    let moved = false;
    let initialposx, initialposy;
    let countmove = 0;
    const texteSVR = this.texteService;
    const photoid = this.photo_id;
    const imgref = this.myImg;
    const doc = this.document;
    const logger = this.logger;
    const imgratio = this.imgratio;
    const divimgratio = this.divimgratio;
    const imgratio_x = this.imgratio_x;
    const imgratio_y = this.imgratio_y;
    // let txt = this.selectedText.texte;
    const boundaryX1 = 0 + imgtopleft_x;
    const boundaryX2 = this.myImg.nativeElement.clientWidth - imgtopleft_x;
    const boundaryY1 = 0 + imgtopleft_y;
    const boundaryY2 = this.myImg.nativeElement.clientHeight - imgtopleft_y;

    this.logger.debug('make drag element  ', elmnt);
    // elmnt.onmousedown = dragMouseDown;
    // elmnt.ontouchstart = dragMouseDown;
    elmnt.addEventListener('mousedown', dragMouseDown);
    elmnt.addEventListener('touchstart', touchdragMouseDown, { passive: false });
    // elmnt.addEventListener("click", function (event) { console.log("Désolé ! preventDefault() ne vous laissera pas cocher ceci."); event.preventDefault(); }, false,);
    // elmnt.addEventListener('touchstart', dragMouseDown, { passive: false });

    this.logger.debug('boundary : ' + boundaryX2 + ' , ' + boundaryY2);

    function constrain(n, lower, upper) {
      if (n > upper) { return upper; } else if (n < lower) { return lower; } else { return n; }
    }
    function touchdragMouseDown(e) {
      logger.debug('touchdragMouseDown \n');
      e.preventDefault();
      dragMouseDown(e);
    }
    function dragMouseDown(e) {
      //  e = e || window.event;

      // for mobile2
      if (e.touches) { e = e.touches[0]; }
      // get the mouse cursor position at startup:

      moved = false;
      initialposx = e.clientX;
      initialposy = e.clientY;
      logger.debug('dragMouseDown \n');
      logger.debug('dragMouseDown ', initialposx, initialposy);
      //      console.log("e ", e);
      logger.debug('e ', e.target.id);
      logger.debug('ed ', doc.getElementById(e.target.id).dataset.textid);


      pos3 = e.clientX;
      pos4 = e.clientY;

      selectedElement = e.target;

      // logger.debug('click initial : x ' + pos3 + ' , y ' + pos4);
      document.addEventListener('mouseup', closeDragElement);
      document.addEventListener('mousemove', elementDrag);
      selectedElement.addEventListener('touchend', touchcloseDragElement, { passive: false });
      selectedElement.addEventListener('touchcancel', touchcloseDragElement, { passive: false });
      selectedElement.addEventListener('touchmove', touchelementDrag, { passive: false });
      // document.addEventListener('touchend', closeDragElement);
      // document.addEventListener('touchmove', elementDrag);
      // document.addEventListener('touchend', closeDragElement, { passive: false });
      // document.addEventListener('touchmove', elementDrag, { passive: false });
      // document.onmouseup = closeDragElement;
      // document.onmousemove = elementDrag;
      // to support touch screen
      // document.ontouchend = closeDragElement;
      // document.ontouchmove = elementDrag;

      bbox = selectedElement.getBoundingClientRect();
      // logger.debug('bbox : x ' + bbox.x + ' , y ' + bbox.y + ' , w ' + bbox.width + ' , h ' + bbox.height);
      // logger.debug('eofset : x ' + e.target.offsetLeft + ' , y ' + e.target.offsetTop);
      minX = boundaryX1;
      maxX = boundaryX2 - bbox.width;
      minY = boundaryY1;
      maxY = boundaryY2 - bbox.height;
      // logger.debug('min : x ' + minX + ' , y ' + minY + ' , max : x ' + maxX + ' , y ' + maxY);
    }
    function touchelementDrag(e) {
      logger.debug('touchelementDrag element drag e  ');
      e.preventDefault();
      elementDrag(e)
    }
    function elementDrag(e) {
      logger.debug('element drag e  ');
      countmove += 1;

      //  e = e || window.event;
      // calculate the new cursor position:
      // console.log("elementDrag \n");
      logger.debug('element drag e : textid ' + e.target.dataset.textid + ' , id ' + e.target.id);
      moved = true;


      //for touchscreen case
      if (e.touches) { e = e.touches[0]; }


      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      logger.debug('elementDrag new pos : x ' + pos3 + ' , y ' + pos4 + ' delta pos : x ' + pos1 + ' , y ' + pos2);
      // set the element's new position:
      //  if ((elmnt.offsetTop - pos2) <
      let dy = elmnt.offsetTop - pos2;
      let dx = elmnt.offsetLeft - pos1
      logger.debug('dx  ' + dx + ' , dy ' + dy);
      if (dx < minX) {
        dx = minX;
        logger.debug('dx constrained ' + dx);
      } else if (dx > maxX) {
        logger.debug('dx constrained ' + dx);
        dx = maxX;
        // logger.debug('dx constrained ' + dx);
      }
      if (dy < minY) {
        logger.debug('dy constrained ' + dx);
        dy = minY;
        // logger.debug('dy constrained ' + dy);
      } else if (dy > maxY) {
        logger.debug('dy constrained ' + dx);
        dy = maxY;
        // logger.debug('dy constrained ' + dy);
      }
      // logger.debug('constrained dx  ' + dx + ' , dy ' + dy);
      elmnt.style.top = dy + 'px';
      elmnt.style.left = dx + 'px';
      // elmnt.style.top = (elmnt.offsetTop - pos2) + 'px';
      // elmnt.style.left = (elmnt.offsetLeft - pos1) + 'px';

      let line = doc.getElementById('line' + selectedElement.dataset.textid);
      if (line == null) { logger.debug('line not found'); }
      let arrowPoints;
      if ((txt.type === TexteType.pensee) || (txt.type === TexteType.parole)) {
        // const line = doc.getElementById('line' + e.target.dataset.textid);
        // logger.debug('line : line' + selectedElement.dataset.textid);
        // console.log('line : line' + e.target.dataset.textid);
        // line.setAttribute('y2', String(dy));
        // line.setAttribute('x2', String(dx));
        arrowPoints = computeArrowPoints8(logger, elmnt.offsetLeft, elmnt.offsetTop,
          elmnt.offsetWidth, elmnt.offsetHeight,
          parseInt(line.getAttribute('x1'), 10), parseInt(line.getAttribute('y1'), 10));
        line.setAttribute('y2', String(arrowPoints.y2));
        line.setAttribute('x2', String(arrowPoints.x2));
      }
      // const txtelem = document.getElementById(e.target.id);
      // cureent text is in variable set by make drag
      // let txt: Texte;
      // self.texteService.getTexte(parseInt(e.target.id, 10)).subscribe(texte => {
      //   txt = texte;
      //   console.log('element drag txt ' + texte);

      // if (txt.type === TexteType.pensee) {
      //   updateThoughtLine(logger, selectedElement.dataset.textid,
      //     parseInt(line.getAttribute('x1'), 10), parseInt(line.getAttribute('y1'), 10), arrowPoints.x2, arrowPoints.y2, 4, 5);
      // } else if (txt.type === TexteType.parole) {
      //   updateSpeakLine(logger, selectedElement.dataset.textid,
      //     parseInt(line.getAttribute('x1'), 10), parseInt(line.getAttribute('y1'), 10),
      //     arrowPoints.x2, arrowPoints.y2, arrowPoints.x1, arrowPoints.y1);
      // } else if (txt.type === TexteType.texte) {
      //   // updateSpeakLine(logger, selectedElement.dataset.textid,
      //   //   parseInt(line.getAttribute('x1'), 10), parseInt(line.getAttribute('y1'), 10),
      //   //   arrowPoints.x2, arrowPoints.y2, arrowPoints.x1, arrowPoints.y1);
      // }
      // });

      // this.selectedElement.arrow_x * imgratio, this.selectedElement.arrow_y * imgratio);
      // txt.arrow_x * imgratio, txt.arrow_y * imgratio);
      // logger.debug('arrow points :' + JSON.stringify(arrowPoints));
      // line.setAttribute('y1', String((elmnt.offsetTop - pos2)));
      // line.setAttribute('x1', String(elmnt.offsetLeft - pos1));

    }


    function touchcloseDragElement(e) {
      // console.log("closeDragElement \n");
      logger.debug('touchcloseDragElement \n');
      e.preventDefault();
      closeDragElement(e);
    }
    function closeDragElement(e) {
      // console.log("closeDragElement \n");
      logger.debug('closeDragElement \n');

      selectedElement = e.target;

      document.removeEventListener('mouseup', closeDragElement);
      document.removeEventListener('mousemove', elementDrag);

      selectedElement.removeEventListener('touchend', touchcloseDragElement, { passive: false });
      selectedElement.removeEventListener('touchcancel', touchcloseDragElement, { passive: false });
      selectedElement.removeEventListener('touchmove', touchelementDrag, { passive: false });
      // document.removeEventListener('touchend', closeDragElement);
      // document.removeEventListener('touchmove', elementDrag);
      // document.onmouseup = null;
      // document.onmousemove = null;
      // document.ontouchmove = null;
      // document.ontouchend = null;
      let deltamovex = Math.abs(pos3 - initialposx);
      let deltamovey = Math.abs(pos4 - initialposy);
      if ((deltamovex > 2) || (deltamovey > 2)) {
        // if (moved && (countmove > 1)) {
        logger.debug('closeDragElement moved');
        // logger.debug('top ' + elmnt.style.top + 'left ' + elmnt.style.left + '\n');
        // this.setTextePos(parseInt(elmnt.style.left, 10), parseInt(elmnt.style.top, 10));

        let posx = parseInt(elmnt.style.left, 10);
        let posy = parseInt(elmnt.style.top, 10);

        // this.selectedText.position_x = posx;
        // this.selectedText.position_y = posy;

        const newTexte = new Texte();

        // newTexte.id = parseInt(e.target.id, 10);
        newTexte.id = parseInt(e.target.dataset.textid, 10);
        // newTexte.id = parseInt(doc.getElementById(e.target.id).dataset.textid, 10);
        // newTexte.id = e.target.id;
        // const imgratio = imgref.nativeElement.clientWidth / imgref.nativeElement.naturalWidth;
        let newposx: number;
        let newposy: number;
        posx -= imgtopleft_x;
        posy -= imgtopleft_y;
        logger.debug('closeDragElement corrected pos :' + ' ' + posx + ' ' + posy);
        logger.debug('closeDragElement imgratioxy pos :' + ' ' + imgratio_x + ' ' + imgratio_y);
        logger.debug('closeDragElement imgtopleft  :' + ' ' + imgtopleft_x + ' ' + imgtopleft_y);
        logger.debug('closeDragElement imgration divimgratio  :' + ' ' + imgratio + ' ' + divimgratio);
        if (imgratio > divimgratio) {
          newposx = Math.floor(posx / imgratio_x);
          newposy = Math.floor(posy / imgratio_x);
        }
        else {
          newposx = Math.floor(posx / imgratio_y);
          newposy = Math.floor(posy / imgratio_y);
        }
        logger.debug('closeDragElement newpos :' + ' ' + newposx + ' ' + newposy);

        if (e.target.id.startsWith('svg')) {
          // logger.debug('arrow_x et y');
          newTexte.arrow_x = newposx;
          newTexte.arrow_y = newposy;
        } else {
          // logger.debug('position et y');
          newTexte.position_x = newposx;
          newTexte.position_y = newposy;
        }
        // logger.debug('photoid :' + photoid);
        newTexte.photo = photoid;
        // const textDiv = document.getElementById('txt' + String(newTexte.id));
        const textDiv = document.getElementById(String(newTexte.id));
        // this.logger.debug('text :' + e.target.textContent);
        // newTexte.texte = e.target.textContent;
        // logger.debug('text :' + textDiv.textContent);
        newTexte.texte = textDiv.textContent;
        // logger.debug('newtext :' + JSON.stringify(newTexte));
        //      this.texteService.updateTexte(this.selectedText)

        var result = understestes.value.map(el => el.id.toString() == selectedElement.dataset.textid ? {
          ...el, position_x: newposx, position_y: newposy
        } : el);
        understestes.next(result);
        texteSVR.partialUpdateTexte(newTexte)
          .subscribe(txt => {
            // logger.debug('setTextePos done x y  :' + posx + ' ' + posy);
          });
      }
      else {
        logger.debug('not moved');
        textClicked(e)
        // self.textClicked(e)
      }
      moved = false;
      countmove = 0;
    }

    function doTextSelected(id, clickedTextDiv) {
      logger.debug('EditPhotoComponent doTextSelected id :' + id);
      if (self.selectedTextId) {
        logger.debug('EditPhotoComponent doTextSelected this.selectedTextId  :' + self.selectedTextId);
      }
      if (self.selectedTextId == null) {
        self.selectedTextId = id;
        self.selectedTextElem = clickedTextDiv;
        self.selectedTextText = text;
        self.selectedTextEditable = false;
        // text to see if cursor ok when no drag
        // clickedTextDiv.onmousedown = null;
        // clickedTextDiv.ontouchstart = null;
        //do not remove listener for second click to seteditable
        // clickedTextDiv.removeEventListener('mousedown', dragMouseDown);
        // clickedTextDiv.removeEventListener('touchstart', touchdragMouseDown, { passive: false });
        // clickedTextDiv.removeEventListener('touchstart', dragMouseDown, { passive: false });
        highlightTexte(id, self);
      } else if (id !== self.selectedTextId) {

        const seletedtext = document.getElementById(self.selectedTextId) as HTMLImageElement;;
        unhighlightTexte(self.selectedTextId);
        // text to see if cursor ok when no drag
        // seletedtext.onmousedown = dragMouseDown;
        // seletedtext.ontouchstart = dragMouseDown;
        seletedtext.addEventListener('mousedown', dragMouseDown);
        seletedtext.addEventListener('touchstart', touchdragMouseDown, { passive: false });
        clickedTextDiv.removeEventListener('mousedown', dragMouseDown);
        clickedTextDiv.removeEventListener('touchstart', touchdragMouseDown, { passive: false });
        // seletedtext.addEventListener('touchstart', dragMouseDown, { passive: false });
        highlightTexte(id, self);
        self.selectedTextId = id;
        self.selectedTextElem = clickedTextDiv;
        self.selectedTextText = text;
        self.selectedTextEditable = false;
      } else { // same as already selected
        // unhighlightTexte(this.selectedTextId);
        // this.selectedTextId = null;
        logger.debug('EditPhotoComponent doTextSelected on same selectedtextid :' + self.selectedTextId);
        seteditable(id, self);
        self.selectedTextEditable = true;
        //remove listener after second click and seteditable
        clickedTextDiv.removeEventListener('mousedown', dragMouseDown);
        clickedTextDiv.removeEventListener('touchstart', touchdragMouseDown, { passive: false });
      }
      logger.debug('EditPhotoComponent doTextSelected selectedtextid :' + self.selectedTextId);
    }


    function textClicked(e: Event) {
      let clickedTextDiv: HTMLDivElement;
      let parentTextDiv: HTMLDivElement;
      let selectedTextId: string;

      console.log('textClick');
      clickedTextDiv = e.target as HTMLDivElement;

      console.log('EditPhotoComponent textclicked id :' + clickedTextDiv.id);
      doTextSelected(clickedTextDiv.id, clickedTextDiv);
      return;
      // should store text id in the dom
      console.log('EditPhotoComponent textclicked id :' + clickedTextDiv.id);
      parentTextDiv = clickedTextDiv.parentNode as HTMLDivElement;
      console.log('EditPhotoComponent textclicked parent id :' + parentTextDiv.id);
      console.log('EditPhotoComponen))t textclicked parent selectedtextid i :' + parentTextDiv.dataset.selectedtextid);
      selectedTextId = parentTextDiv.dataset.selectedtextid;
      if (selectedTextId) {
        unhighlightTexte(selectedTextId);
      }
      if ((selectedTextId == null) || (clickedTextDiv.id !== selectedTextId)) {
        parentTextDiv.setAttribute('data-selectedtextid', clickedTextDiv.id);
        // should also unhilight previous text
        highlightTexte(clickedTextDiv.id, this);
      } else if ((selectedTextId) || (clickedTextDiv.id === selectedTextId)) {
        parentTextDiv.removeAttribute('data-selectedtextid');
      }
    }

  }

  makeDraggable(svg) {
    const logger = this.logger;
    const imgratio = this.imgratio;
    const divimgratio = this.divimgratio;
    const imgratio_x = this.imgratio_x;
    const imgratio_y = this.imgratio_y;
    const imgtopleft_x = this.imgtopleft_x;
    const imgtopleft_y = this.imgtopleft_y;
    const doc = this.document;
    const imgref = this.myImg;
    const photo = this.photo;
    const photoid = this.photo_id;
    const texteSVRC = this.texteService;
    const self = this;
    const lsvg = svg;
    let selectedTextDiv: HTMLDivElement;
    const understestes = this._textes;
    // const txt: Texte = understestes.value.find(elem => svg.target.id === elem.id);
    // const txt: Texte = { ...text };

    this.logger.debug('makeDraggable svg' + JSON.stringify(svg));
    svg.addEventListener('mousedown', startDrag);
    // svg.addEventListener('mousemove', drag);
    // svg.addEventListener('mouseup', endDrag);
    // svg.addEventListener('mouseleave', endDrag);
    svg.addEventListener('touchstart', startDrag);
    // svg.addEventListener('touchmove', drag);
    // svg.addEventListener('touchend', endDrag);
    // svg.addEventListener('touchleave', endDrag);
    // svg.addEventListener('touchcancel', endDrag);

    let selectedElement, offset, transform,
      bbox, minX, maxX, minY, maxY, confined;

    const boundaryX1 = imgtopleft_x;
    const boundaryX2 = this.myImg.nativeElement.clientWidth - imgtopleft_x;
    const boundaryY1 = imgtopleft_y;
    const boundaryY2 = this.myImg.nativeElement.clientHeigth - imgratio_y;

    function getMousePosition(evt) {
      const CTM = svg.getScreenCTM();
      // logger.debug('CTM : ' + CTM);
      if (evt.touches) { evt = evt.touches[0]; }
      return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d
      };
    }

    function startDrag(evt) {
      logger.debug('startDrag svg');
      logger.debug('startDrag svg id ' + evt.target.id);
      logger.debug('startDrag svg textid ' + evt.target.dataset.textid);
      if (self.selectedTextId) {
        self.makeDragElement(self.selectedTextElem, self.selectedTextText)
        unhighlightTexte(self.selectedTextId);
        self.selectedTextId = null;
        self.selectedTextElem = null;
        self.selectedTextText = null;
      }


      if (evt.target.classList.contains('draggable')) {
        logger.debug('start drag svg on draggable ');
        svg.addEventListener('mousemove', drag);
        svg.addEventListener('mouseup', endDrag);
        svg.addEventListener('mouseleave', endDrag);
        svg.addEventListener('touchmove', drag);
        svg.addEventListener('touchend', endDrag);
        svg.addEventListener('touchleave', endDrag);
        svg.addEventListener('touchcancel', endDrag);
        selectedElement = evt.target;
        offset = getMousePosition(evt);

        // Make sure the first transform on the element is a translate transform
        const transforms = selectedElement.transform.baseVal;

        if (transforms.length === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
          // Create an transform that translates by (0, 0)
          const translate = svg.createSVGTransform();
          translate.setTranslate(0, 0);
          selectedElement.transform.baseVal.insertItemBefore(translate, 0);
        }

        // Get initial translation
        transform = transforms.getItem(0);
        offset.x -= transform.matrix.e;
        offset.y -= transform.matrix.f;

        logger.debug('Drag  offset.xy ' + offset.x + ' ' + offset.y);
        bbox = selectedElement.getBBox();
        confined = evt.target.classList.contains('confine');
        if (confined) {
          bbox = selectedElement.getBBox();
          // minX = boundaryX1 - bbox.x;
          // maxX = boundaryX2 - bbox.x - bbox.width;
          // minY = boundaryY1 - bbox.y;
          // maxY = boundaryY2 - bbox.y - bbox.height;
          // minX = boundaryX1;
          // maxX = boundaryX2;
          // minY = boundaryY1;
          // maxY = boundaryY2;
        }
        if (evt.target.dataset.textid != null) {
          // logger.debug('teste id' + evt.target.dataset.textid);
          selectedTextDiv = <HTMLDivElement>document.getElementById(evt.target.dataset.textid);
          logger.debug('texte txt' + selectedTextDiv.textContent);
        }
      }
      else
        logger.debug('start drag svg on non draggable ');

    }

    function drag(evt) {
      logger.debug('Drag svg');
      if (selectedElement) {
        evt.preventDefault();
        logger.debug('Drag svg selected element');

        const coord = getMousePosition(evt);
        logger.debug('Drag svg coord xy ' + ' ' + coord.x + ' ' + coord.y);
        // let dx = coord.x - offset.x;
        // let dy = coord.y - offset.y;

        // logger.debug('Drag svg dxy ' + ' ' + dx + ' ' + dy);
        // if (confined) {
        //   if (dx < minX) { dx = minX; } else if (dx > maxX) { dx = maxX; }
        //   if (dy < minY) { dy = minY; } else if (dy > maxY) { dy = maxY; }
        // }
        // logger.debug('Drag svg dxy after confined ' + ' ' + dx + ' ' + dy);

        // transform.setTranslate(dx, dy);

        selectedElement.setAttribute('cx', String(coord.x));
        selectedElement.setAttribute('cy', String(coord.y));
        // need to transform the associated setextDivgmento
        if (evt.target.dataset.textid != null) {
          // logger.debug('line id' + 'line' + evt.target.dataset.textid);
          const line = document.getElementById('line' + evt.target.dataset.textid);
          line.setAttribute('x1', String(coord.x));
          line.setAttribute('y1', String(coord.y));

          // we need too to recompute start of line based on new end of line position
          // logger.debug('bbox ' + JSON.stringify(bbox));
          // const attachPoint = computeAttachPoint(selectedElement);
          const arrowPoints = computeArrowPoints8(logger, selectedTextDiv.offsetLeft, selectedTextDiv.offsetTop,
            selectedTextDiv.offsetWidth, selectedTextDiv.offsetHeight,
            coord.x, coord.y);
          // selectedTextDiv.arrow_x * imgratio, this.selectedTextDiv.arrow_y * imgratio);
          // txt.arrow_x * imgratio, txt.arrow_y * imgratio);
          // logger.debug('arrow points :' + JSON.stringify(arrowPoints));
          line.setAttribute('x2', String(arrowPoints.x1));
          line.setAttribute('y2', String(arrowPoints.y1));
          // const txtelem = document.getElementById(evt.target.dataset.textid);
          // let txt: Texte;
          // self.texteService.getTexte(parseInt(txtelem.id, 10)).subscribe(texte => {
          //   txt = texte;
          if (evt.target.dataset.textid) {
            // logger.debug('Drag svg textid', evt.target.dataset.textid);
            const txt: Texte = understestes.value.find(elem => +evt.target.dataset.textid === elem.id);
            // logger.debug('Drag svg undertestes value', understestes.value);
            // logger.debug('Drag svg undertestes selfvalue', self._textes.value);
            // logger.debug('Drag svg text', JSON.stringify(txt));
            // if (txt.type === TexteType.pensee) {
            //   updateThoughtLine(logger, selectedElement.dataset.textid,
            //     parseInt(line.getAttribute('x1'), 10), parseInt(line.getAttribute('y1'), 10),
            //     arrowPoints.x2, arrowPoints.y2, 4, 5);
            // } else if (txt.type === TexteType.parole) {
            //   updateSpeakLine(logger, selectedElement.dataset.textid,
            //     parseInt(line.getAttribute('x1'), 10), parseInt(line.getAttribute('y1'), 10),
            //     arrowPoints.x2, arrowPoints.y2, arrowPoints.x1, arrowPoints.y1);
            // } else if (txt.type === TexteType.texte) {
            //   // updateSpeakLine(logger, selectedElement.dataset.textid,
            //   //   parseInt(line.getAttribute('x1'), 10), parseInt(line.getAttribute('y1'), 10),
            //   //   arrowPoints.x2, arrowPoints.y2, arrowPoints.x1, arrowPoints.y1);
            // }
          }
          // });
        }
      }
    }

    function endDrag(evt) {
      logger.debug('endDrag svg');
      logger.debug('endDrag svg evt : ' + JSON.stringify(evt));

      // if (evt.target.classList.contains('draggable')) {
      // logger.debug('remove listener on draggable');
      lsvg.removeEventListener('mousemove', drag);
      lsvg.removeEventListener('mouseup', endDrag);
      lsvg.removeEventListener('mouseleave', endDrag);
      lsvg.removeEventListener('touchmove', drag);
      lsvg.removeEventListener('touchend', endDrag);
      lsvg.removeEventListener('touchleave', endDrag);
      lsvg.removeEventListener('touchcancel', endDrag);
      // }

      if (selectedElement) {
        logger.debug('end drag on selected element');
        if (selectedElement.id.startsWith('circle')) {
          logger.debug('end draggable on circle');

          const newTexte = new Texte();
          newTexte.id = parseInt(selectedElement.dataset.textid, 10);
          newTexte.photo = photoid;
          // logger.debug('circle  id' + selectedElement.id + ' txtid ' + newTexte.id);
          // const textDiv = document.getElementById(String(newTexte.id));
          let posx = parseInt(selectedElement.getAttribute('cx'), 10);
          let posy = parseInt(selectedElement.getAttribute('cy'), 10);
          posx -= imgtopleft_x;
          posy -= imgtopleft_y;
          // const posx = parseInt(selectedElement.style.left, 10);
          // const posy = parseInt(selectedElement.style.top, 10);
          // logger.debug('circle  posx' + posx + ' , ' + posy);
          // const imgratio = imgref.nativeElement.clientWidth / imgref.nativeElement.naturalWidth;
          // logger.debug('imgratio ' + imgratio);
          // logger.debug('divimgratio ' + divimgratio);
          // logger.debug('imgratio_x ' + imgratio_x);
          // logger.debug('imgratio_y ' + imgratio_y);
          let newposx: number;
          let newposy: number;

          logger.debug('endDrag circle  cx cy : ' + posx + ' , ' + posy);
          logger.debug('endDrag imgratio div ratiox ratioy: ' + imgratio + ' , ' + divimgratio + ' ' + '' + imgratio_x + '' + imgratio_y);
          if (imgratio > divimgratio) {
            newposx = Math.floor(posx / imgratio_x);
            newposy = Math.floor(posy / imgratio_x);
          }
          else {
            newposx = Math.floor(posx / imgratio_y);
            newposy = Math.floor(posy / imgratio_y);
          }
          if (newposx < 0) newposx = 0;
          if (newposy < 0) newposy = 0;
          if (newposx > photo.size_x) newposx = photo.size_x;
          if (newposy > photo.size_y) newposy = photo.size_y;
          newTexte.arrow_x = newposx;
          newTexte.arrow_y = newposy;
          logger.debug('endDrag circle  arrow newposx newposy' + newTexte.arrow_x + ' , ' + newTexte.arrow_y);

          var result = understestes.value.map(el => el.id.toString() == selectedElement.dataset.textid ? {
            ...el, arrow_x: newposx, arrow_y: newposy
          } : el);
          understestes.next(result);
          texteSVRC.partialUpdateTexte(newTexte)
            .subscribe(txt => {
              logger.debug('endDrag setTextePos done x y  :' + newposx + ' ' + newposy);
            });
        }
      }
      else
        logger.debug('endDrag svg no selected element');
      selectedElement = null;
    }
  }


  shapephyl() {
    this.logger.debug('EditPhotoComponent shapephyl txtid: ', this.selectedTextId);
  }
  typephyl() {
    this.logger.debug('EditPhotoComponent typephyl txtid: ', this.selectedTextId);
  }
  setbgcolor() {
    this.logger.debug('EditPhotoComponent setbgcolor txtid: ', this.selectedTextId);
  }
  increm(fontsz: string) {
    var match = fontsz.match(/^(\d+(?:\.\d+)?)rem/);
    var numericValue = match ? match[1] : null;
    let newfontsz = (+numericValue + 0.1).toFixed(2);
    this.logger.debug('fontsz  ' + numericValue);
    this.logger.debug('newfontsz  ' + newfontsz);
    return newfontsz + 'rem';
  }
  decrement(fontsz: string) {
    var match = fontsz.match(/^(\d+(?:\.\d+)?)rem/);
    var numericValue = match ? match[1] : null;
    let newfontsz = (+numericValue - 0.1).toFixed(2);
    this.logger.debug('fontsz  ' + numericValue);
    this.logger.debug('newfontsz  ' + newfontsz);
    return newfontsz + 'rem';
  }

  NeedTextSelected(action) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      action: action,
    };

    const dialogRef = this.dialog.open(NeedTextSelectedDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        this.logger.debug("Dialog NeedTextSelectedDialogComponent called");
      });
  }


  increasefont() {
    if (this.selectedTextId) {

      this.logger.debug('EditPhotoComponent increasefont txtid: ', this.selectedTextId);
      this.logger.debug('EditPhotoComponent txt : ', JSON.stringify(this._textes.value));
      var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, font_size: this.increm(el.font_size) } : el);
      this._textes.next(result);
      let item1 = result.find(i => i.id.toString() === this.selectedTextId);
      this.logger.debug('EditPhotoComponent item1 : ', JSON.stringify(item1));
      this.texteService.partialUpdateTexte(item1)
        .subscribe(txt => {
          this.logger.debug('validate text ' + JSON.stringify(txt));
        });
    }
    else {
      this.logger.debug('EditPhotoComponent increasefont this.selectedTextId  is null');
      this.NeedTextSelected('increasefont');
      // const dialogConfig = new MatDialogConfig();
      // dialogConfig.disableClose = true;
      // dialogConfig.autoFocus = true;

      // dialogConfig.data = {
      //   action: 'this.photo_id',
      // };

      // const dialogRef = this.dialog.open(NeedTextSelectedDialogComponent, dialogConfig);
      // dialogRef.afterClosed().subscribe(
      //   data => {
      //     this.logger.debug("Dialog NeedTextSelectedDialogComponent called");
      //   });
    }

  }
  decreasefont() {
    if (this.selectedTextId) {
      this.logger.debug('EditPhotoComponent decreasefont txtid: ', this.selectedTextId);
      this.logger.debug('EditPhotoComponent txt : ', JSON.stringify(this._textes.value));
      var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, font_size: this.decrement(el.font_size) } : el);
      this._textes.next(result);
      let item1 = result.find(i => i.id.toString() === this.selectedTextId);
      this.logger.debug('EditPhotoComponent item1 : ', JSON.stringify(item1));
      this.texteService.partialUpdateTexte(item1)
        .subscribe(txt => {
          this.logger.debug('validate text ' + JSON.stringify(txt));
        });
    }
    else {
      this.logger.debug('EditPhotoComponent increasefont this.selectedTextId  is null');
      this.NeedTextSelected('decreasefont');
    }
  }
  newTextDialog() {
    this.logger.debug('EditPhotoComponent newTextDialog: ');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: this.photo_id,
    };

    const dialogRef = this.dialog.open(NewTextDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log("Dialog output:", data)
        const currentValue = this._textes.value
        const result = [...currentValue, data];
        // var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, type: data.type } : el);
        this._textes.next(result);
        // let item1 = result.find(i => i.id.toString() === this.selectedTextId);
        // this.logger.debug('EditPhotoComponent item1 : ', JSON.stringify(item1));
      });
  }
  textTypeDialog() {
    this.logger.debug('EditPhotoComponent textTypeDialog: ');

    if (this.selectedTextId) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        id: this.photo_id,
        text_id: this.selectedTextId,
      };

      const dialogRef = this.dialog.open(TextTypeDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          console.log("Dialog output:", data)
          var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, type: data.type, display: data.display } : el);
          this._textes.next(result);
          let item1 = result.find(i => i.id.toString() === this.selectedTextId);
          this.logger.debug('EditPhotoComponent item1 : ', JSON.stringify(item1));
        });
    }
    else {
      this.logger.debug('EditPhotoComponent increasefont this.selectedTextId  is null');
      this.NeedTextSelected('textTypeDialog');
    }
  }
  textShapeDialog() {
    this.logger.debug('EditPhotoComponent textShapeDialog: ');

    if (this.selectedTextId) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        id: this.photo_id,
        text_id: this.selectedTextId,
      };

      const dialogRef = this.dialog.open(TextShapeDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          console.log("Dialog output:", data)
          var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, shape: data.shape } : el);
          this._textes.next(result);
          let item1 = result.find(i => i.id.toString() === this.selectedTextId);
          this.logger.debug('EditPhotoComponent item1 : ', JSON.stringify(item1));
        });
    }
    else {
      this.logger.debug('EditPhotoComponent increasefont this.selectedTextId  is null');
      this.NeedTextSelected('textShapeDialog');
    }
  }
  backgroudnColorPickerDialog() {
    this.logger.debug('EditPhotoComponent backgroudnColorPickerDialog: ');

    if (this.selectedTextId) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '400px';
      dialogConfig.width = '230px';
      dialogConfig.position = {
        right: '10px', top: '100px'
      }

      dialogConfig.data = {
        text_id: this.selectedTextId,
        id: this.photo_id,
        color: this.selectedTextText.background_color,
      };

      const dialogRef = this.dialog.open(BackgroundColorPickerDialogComponent, dialogConfig);
      dialogRef.componentInstance.colorChange.subscribe(
        data => {
          console.log("BackgroundColorPickerDialogComponent output:", data)
          var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, background_color: data } : el);
          this._textes.next(result);
          let item1 = result.find(i => i.id.toString() === this.selectedTextId);
          this.logger.debug('EditPhotoComponent item1 : ', JSON.stringify(item1));
        });
      dialogRef.afterClosed().subscribe(
        data => {
          console.log("Dialog output:", data)
          var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, background_color: data } : el);
          this._textes.next(result);
          let item1 = result.find(i => i.id.toString() === this.selectedTextId);
          this.logger.debug('EditPhotoComponent item1 : ', JSON.stringify(item1));
          this.texteService.partialUpdateTexte(item1)
            .subscribe(txt => {
              this.logger.debug('validate text ' + JSON.stringify(txt));
            });
        });
    }
    else {
      this.logger.debug('EditPhotoComponent increasefont this.selectedTextId  is null');
      this.NeedTextSelected('backgroudnColorPickerDialog');
    }
  }
  deleteTextDialog() {
    this.logger.debug('EditPhotoComponent deleteTextDialog: ');

    if (this.selectedTextId) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        id: this.photo_id,
        text_id: this.selectedTextId,
      };

      this.deleted_text_id = this.selectedTextId;
      const dialogRef = this.dialog.open(DeleteTextDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          console.log("Delete Dialog output:", data)
          const text_values = this._textes.getValue();
          console.log("Delete text_value:", JSON.stringify(text_values));
          text_values.forEach((item, index) => {
            if (item.id.toString() == this.selectedTextId) {
              text_values.splice(index, 1);
              console.log("Delete splice index" + index + ' id ' + item.id + 'text' + item.texte);
              console.log("Delete new text_value:", JSON.stringify(text_values));
            }
          });
          this._textes.next(text_values);
          this.selectedTextId = null;
          this.selectedTextElem = null;
          this.selectedTextText = null;
        });
    }
    else {
      this.logger.debug('EditPhotoComponent increasefont this.selectedTextId  is null');
      this.NeedTextSelected('deleteTextDialog');
    }
  }
  back() {
    this.logger.debug('back ');
    this.photoService.changeFullscreen(false);
    // this.location.back();

    if (this.episodeMode == 'edit')
      this.router.navigate(['/' + this.languageService.getiCurrentLanguage() + '/episodes/' + this.photo.episode], { fragment: 'divimage' + this.photo_id });
    else
      this.location.back();
    // this.router.navigate(['/photos/']);

  }

  newPhotoDialog(): void {
    this.logger.debug('newPhotoDialog ');
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      photo_type: 'photo',
      object_id: 555,
      episode_id: 1,
      uuidfname: 'zaeaze'
    };
    dialogConfig.height = '600px';
    dialogConfig.width = '400px';
    dialogConfig.position = {
      left: '10px', top: '10px'
    }

    const dialogRef = this.dialog.open(TelechargeComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        console.log('The telecharge dialog was closed with result : ' + JSON.stringify(result));
        this.createPhoto(result.uuidFileName, result.size_x, result.size_y, 1).subscribe(id => {
          this.photo_id = id;
          this.episodeMode = 'editphotoshoot';
          // should with set the blob tags at this point
          this.refreshPhoto();
        }
        );

      }
      else
        console.log('The telecharge dialog was closed with undefined photoId : ');
    });
  }
  createPhoto(uuidFileName: string, size_x: number, size_y: number, episode_id: number): Observable<number> {
    const photo: Photo = new Photo();
    let photo_id: number = 0;
    photo.desc = 'desccccc ' + uuidFileName;
    photo.numero = 0;
    photo.episode = episode_id;
    // photo.url = 'https://sapicafrou.blob.core.windows.net/uploads/' + fileURL;
    photo.url = '';
    // photo.photocontainer = 'uploads';
    photo.photocontainer = this.appconfigService.config.STORAGE_CONTAINER_NAME;
    photo.photoname = uuidFileName;
    photo.size_x = size_x;
    photo.status = StatusType.GOPUBLIC_STATUS
    // photo.status = StatusType.PUBLIC_STATUS
    photo.size_y = size_y;


    return this.photoService.addPhoto(photo)
      .pipe(map(newphoto => { return newphoto.id }));
  }
  likephoto(photo: Photo): void {
    this.photoService.likePhoto(photo).subscribe();
    this.islikedbyme = !this.islikedbyme;
    if (this.islikedbyme)
      this.nblikes += 1;
    else
      this.nblikes -= 1;
  }

  // bookmarkphoto(photo: Photo): void {
  //   this.photoService.bookmarkPhoto(photo).subscribe();
  //   this.isbookmarkedbyme = !this.isbookmarkedbyme;
  //   if (this.isbookmarkedbyme)
  //     this.nbbookmarks += 1;
  //   else
  //     this.nbbookmarks -= 1;;
  // }

  openShareDialog(): void {
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  deletePhotoDialog(photo_id: number): void {
    this.logger.debug('deletePhotoDialog ' + photo_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: photo_id,
    };
    const dialogRef = this.dialog.open(DeletePhotoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.back();
    });
  }

}
function textClick(e: Event) {
  let clickedTextDiv: HTMLDivElement;
  console.log('textClick');
  clickedTextDiv = e.target as HTMLDivElement;
  highlightTexte(clickedTextDiv.id, this);
}

