import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Serie } from '../models/serie';
import { SerieService } from '../services/serie.service';
// import { NumberValueAccessor } from '../../../node_modules/@angular/forms/src/directives';
import { AlertService, AuthenticationService } from '@/_services';
import { User } from '../_models/user';
import { Observable } from 'rxjs';
import { Author } from '@/models/author';
import { AuthorService } from '@/services/author.service';
import { ShareButtons } from 'ngx-sharebuttons/buttons';
// import { ShareService } from 'ngx-share/core';
// :w kimport { ShareButtons } from '';

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageService } from '@/services/image.service';
import { StarRatingColor } from '../star-rating/star-rating.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RatingDialogComponent } from './rating-dialog/rating-dialog.component';
import { SharingDialogComponent } from './sharing-dialog/sharing-dialog.component';
import { FullscreenImageDialogComponent } from '@/fullscreen-image-dialog/fullscreen-image-dialog.component';
import { DeleteEpisodeDialogComponent } from '@/delete-episode-dialog/delete-episode-dialog.component';
import { NGXLogger } from 'ngx-logger';
import { DeleteSerieDialogComponent } from '@/delete-serie-dialog/delete-serie-dialog.component';
import { RatingService } from '@/services/rating.service';
import { Rating } from '@/models/rating';
import { Meta, Title } from '@angular/platform-browser';
import { LanguageService } from '@/services/language.service';
import { AppConfigService } from '@/services/app-config.service';
export interface DialogData {
  animal: string;
  name: string;
}


@Component({
  selector: 'app-serie-detail',
  templateUrl: './serie-detail.component.html',
  styleUrls: ['./serie-detail.component.scss'],
  standalone: false
})

export class SerieDetailComponent implements OnInit {

  @Input() serie: Serie;
  serie$: Observable<Serie>;
  display_comment: boolean = true;
  currentRate = 8;
  currentRate2 = 5;
  pseudo: string;
  author$: Observable<Author>;
  author: Author;
  //  inAuthenticated
  sharedurl: string;

  issubscribedbyme: boolean;
  islikedbyme: boolean;
  isratedbyme: boolean;
  iscommentedbyme: boolean;
  myrating: number;
  nbratings: number;
  nblikes: number;
  nbcomments: number;
  nbsubscriptions: number;
  myserierating: number;
  nbshares: number;

  fbIcon = faFacebookSquare;
  pinIcon = faPinterest;
  tweetIcon = faTwitterSquare;

  constructor(
    private route: ActivatedRoute,
    private appconfigService: AppConfigService,
    private serieService: SerieService,
    private authorService: AuthorService,
    public authenticationService: AuthenticationService,
    private location: Location,
    private imageService: ImageService,
    private languageService: LanguageService,
    private ratingService: RatingService,
    public dialog: MatDialog,
    private router: Router,
    private logger: NGXLogger,
    private meta: Meta,
    private title: Title,
    // public share: ShareButtons
    public fa: FontAwesomeModule
  ) { }

  ngOnInit(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.serie$ = this.getSerie(id);

    // this.sharedurl = window.location.href;
    this.sharedurl = this.appconfigService.config.frontendUrl + this.router.url + '/';

    this.serie$.subscribe(serie => {
      this.serie = serie;
      this.title.setTitle(serie.name)
      this.meta.removeTag('name="title"');
      this.meta.removeTag('name="description"');
      this.meta.addTags([{ name: 'title', content: serie.name },
      { name: 'description', content: serie.desc }
      ]);


      this.meta.removeTag('name="og:title"');
      this.meta.removeTag('name="og:description"');
      this.meta.removeTag('name="og:type"');
      this.meta.removeTag('name="og:locale"');
      this.meta.removeTag('name="og:site_name"');
      this.meta.removeTag('name="og:url"');
      this.meta.removeTag('name="og:image"');
      this.meta.addTags([
        { name: 'og:title', content: serie.name },
        { name: 'og:description', content: serie.desc },
        { name: 'og:type', content: "article" },
        { name: 'og:locale', content: this.languageService.getiCurrentLanguage() },
        { name: 'og:site_name', content: '@picafrou' },
        { name: 'og:url', content: this.sharedurl },
        { name: 'og:image', content: this.imageService.getphotourl(serie.photocontainer, serie.photoname) }
      ]);

      this.meta.removeTag('name="twitter:title"');
      this.meta.removeTag('name="twitter:description"');
      this.meta.removeTag('name="twitter:card"');
      this.meta.removeTag('name="twitter:site"');
      this.meta.removeTag('name="twitter:url"');
      this.meta.removeTag('name="twitter:image"');
      this.meta.addTags([
        { name: 'twitter:title', content: serie.name },
        { name: 'twitter:description', content: serie.desc },
        { name: 'twitter:card', content: "summary" },
        { name: 'twitter:site', content: "@picafrou" },
        { name: 'twitter:url', content: this.sharedurl },
        { name: 'twitter:image', content: this.imageService.getphotourl(serie.photocontainer, serie.photoname) }
      ]);

      if (this.authenticationService.isLoggedIn()) {
        // this.serieService.isSubscribedByMeSerie(id).subscribe(data => this.issubscribedbyme = data);
        this.issubscribedbyme = serie.issubscribedbyme;
        // this.serieService.islikedbymeSerie(id).subscribe(data => this.islikedbyme = data);
        this.islikedbyme = serie.issubscribedbyme;
        // this.serieService.iscommentedbymeSerie(id).subscribe(data => this.iscommentedbyme = data);
        this.iscommentedbyme = serie.iscommentedbyme;
        this.serieService.isratedbymeSerie(id).subscribe(data => { this.isratedbyme = data; });
        this.serieService.myrating(id).subscribe(data => { this.myrating = data; });
      }
      // this.serieService.nbsubscriptions(id).subscribe(data => this.nbsubscriptions = data);
      this.nbsubscriptions = serie.nb_subscribtions;
      // this.serieService.nblikes(id).subscribe(data => this.nblikes = data);
      this.nblikes = serie.nb_likes;
      // this.serieService.nbcomments(id).subscribe(data => this.nbcomments = data);
      this.nbcomments = serie.nb_comments;
      // this.serieService.nbratings(id).subscribe(data => { this.nbratings = data; });
      this.nbratings = serie.nb_raters;

      this.serieService.nbshares(id).subscribe(data => this.nbshares = data);

      // console.log('SerieDetailComponent serie: ' + JSON.stringify(this.serie));
      // this.author$ = this.authorService.getAuthor(this.serie.owner.id);
      // this.author$.subscribe(user => {
      // this.userService.getById(this.serie.owner).subscribe(user => {
      //   this.pseudo = user.pseudo;
      //this.author = user;
      // console.log('SerieDetailComponent user: ' + user.id);
      // console.log('SerieDetailComponent pseudo: ' + user.pseudo);
      // console.log('SerieDetailComponent fin getbyid ');

      // });
      // console.log('SerieDetailComponent fin getserie ');
      // console.log('SerieDetailComponent author: ' + this.serie.owner.toString());
    });
    // console.log('SerieDetailComponent fin ');


    // console.log('SerieDetailComponent sharedurl: ' + this.location.path());
    // console.log('SerieDetailComponent sharedurl: ' + this.route.snapshot.url);
    // console.log('SerieDetailComponent routeurl: ' + this.route.url);
    // console.log('SerieDetailComponent sharedurl: ' + window.location.href);

  }

  rateChange(rating) {
    this.currentRate2 = rating;
    console.log(`rating is ${rating}`);
    console.log(rating);
    this.serieService.rateSerie(this.serie, rating).subscribe();

  }

  //   ngOnChanges(changes) {
  //     if (changes.currentRate ) this.currentRate = 1;
  // //    if (changes.currentRate && isNaN(changes.value.currentValue)) this.value = 0;
  //   }

  getSerie(id: number): Observable<Serie> {
    return this.serieService.getSerie(id);
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.serieService.updateSerie(this.serie)
      .subscribe(() => this.goBack());
  }

  subscribeserie(serie: Serie): void {
    this.serieService.subscribeSerie(serie).subscribe();
    this.issubscribedbyme = !this.issubscribedbyme;
    if (this.issubscribedbyme)
      this.nbsubscriptions += 1;
    else
      this.nbsubscriptions -= 1;
  }

  rate(serie: Serie, rating: number): void {
    this.serieService.rateSerie(serie, rating).subscribe();
  }


  likeserie(serie: Serie): void {
    // serie.to_content_object = `/fr/1.0/api/${this.comment_type}s/${this.object_id}/`;
    this.serieService.addLikeSerie(serie).subscribe();
    this.islikedbyme = !this.islikedbyme;
    if (this.islikedbyme)
      this.nblikes += 1;
    else
      this.nblikes -= 1;

  }

  islikedbymeserie(serieId: number): Observable<boolean> {
    return this.serieService.islikedbymeSerie(serieId);
  }

  openShareDialog(): void {
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openRateDialog(): void {
    const dialogRef = this.dialog.open(RatingDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed with ratingi ' + result.rating);
      let rating = new Rating();
      rating.serie = this.serie.id;
      rating.value = result.rating;
      this.ratingService.addRating(rating).subscribe({

        next: data => {
          console.log('addRating data: ', JSON.stringify(data));
        },
        error: error => {
          this.logger.debug(error);
        }
      });
    });
  }

  openfullscreenimageDialog() {
    this.imageService.ogphotourl(this.serie.photocontainer, this.serie.photoname).subscribe(
      x => {
        const dialog_ref = this.dialog.open(FullscreenImageDialogComponent, {
          panelClass: ['full-screen'],
          data: { imgUrl: x },
          // data: { imgUrl: this.imageService.ogphotourl(this.author.photocontainer, this.author.photoname) },
        });
        dialog_ref.afterClosed().subscribe(result => { });
      });
  }


  deleteSerieDialog(serie_id: number): void {
    this.logger.debug('deleteSerieDialog ' + serie_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: serie_id,
    };
    const dialogRef = this.dialog.open(DeleteSerieDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      this.router.navigate(['/series']);
    });
  }
}

