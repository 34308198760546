import { NgxLoggerLevel } from "ngx-logger";

export const environment = {
  production: true,
  apiUrl: 'https://picafrou.com/en/1.0/api',
  rootUrl: 'https://picafrou.com',
  frontendUrl: 'https://picafrou.com',
  versionCheckURL: 'http://picafrou.com/version.json',
  sasGeneratorUrl: 'https://sapicafrou.azurewebsites.net',
  defaultLanguage: 'en',
  storage_account_name: 'sapicafrou1',
  storage_container_name: 'uploads',
  VAPID_PUBLIC_KEY: 'BM2vjwWQqhGbr5xO31-f_SmXclyiMtQVXkGXooVb7mDZtc886g6k47SFkfe5JGn1QQoDS5uDbslG1VBryAqgzNI',
  logging: {
    level: NgxLoggerLevel.DEBUG,
    enableSourceMaps: true
    // serverLogLevel: NgxLoggerLevel.ERROR
    // serverLogginUrl: 'https://devdactic.free.beeceptor.com/logs'
  },
  googleAnalytics: 'G-MEG5GGHCC4'
};
