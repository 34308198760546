import { Component, Input, OnInit } from '@angular/core';
import { Episode } from '../models/episode';
import { BookmarkService } from '../services/bookmark.service';
import { SubscriptionsService } from '../services/subscriptions.service';
import { FormControl, FormGroup } from '@angular/forms';
import { map, Observable, Subject } from 'rxjs';
import { Page } from '../pagination';
import { Serie } from '@/models/serie';
import { ImageService } from '@/services/image.service';

@Component({
    selector: 'app-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.scss'],
    standalone: false
})
export class SubscriptionsComponent implements OnInit {
  @Input() serie_id: number;
  @Input() author_id: number;
  series: Serie[];
  filterForm: FormGroup;
  series$: Observable<Serie[]>;

  constructor(private subscriptionsService: SubscriptionsService,
    private imageService: ImageService) { }


  ngOnInit() {
    this.series$ = this.getMySubscribedSeries();
  }
  getMySubscribedSeries(): Observable<Serie[]> {
    return this.subscriptionsService.getMySubscribedSeries().pipe(
      map(events => {
        return events.results;
      })
    );
  }


}
