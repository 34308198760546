import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { Texte } from '../models/texte';
import { Comment, CommentType } from '../models/comment';
import { TexteService } from '../services/texte.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Page } from '../pagination';
import { CommentService } from '@/services/comment.service';
import { AuthenticationService } from '@/_services';
import { map, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { CommentSerie } from '@/models/commentserie';
import { CommentSerieService } from '@/services/commentserie.service';
import { CommentEpisodeService } from '@/services/commentepisode.service';
import { CommentPhotoService } from '@/services/commentphoto.service';
import { CommentPhoto } from '@/models/commentphoto';
import { CommentEpisode } from '@/models/commentepisode';
import { UserService } from '@/services/user.service';
import { SerieService } from '@/services/serie.service';
import { EpisodeService } from '@/services/episode.service';
import { PhotoService } from '@/services/photo.service';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
    standalone: false
})
export class CommentsComponent implements OnInit, OnDestroy {
  // @Output() commentSelected = new EventEmitter();
  @Input() serie_id: number;
  @Input() episode_id: number;
  @Input() photo_id: number;
  @Input() comment_id: number;
  @Input() comment_type: string;
  @Input() user_id: number;

  object_id: number;

  showReply = false;
  togglePanel: any = {};
  // commentSub: Subscription;
  //comments: Comment[];
  private _comments = new BehaviorSubject<Comment[]>([]);
  comments$: Observable<Comment[]>;
  // comments$: Observable<Comment[]> = this._comments.asObservable();;

  // filterForm: FormGroup;
  // page: Observable<Page<Texte>>;

  public selectedItem: any;
  public selected = false;

  constructor(
    private serieService: SerieService,
    private episodeService: EpisodeService,
    private photoService: PhotoService,
    private commentService: CommentService,
    public authenticationService: AuthenticationService,
    public userService: UserService,
    private logger: NGXLogger,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.logger.debug('ngOInit comments for cwcomment_type : ' + this.comment_type);
    // this.comments$ = of([]);
    if (this.comment_type == 'serie') {
      if (this.serie_id) {
        this.logger.debug('ngOInit comments for episodeid ' + this.serie_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        this.comments$ = this._comments.asObservable();
        this.getSerieComments(this.serie_id).subscribe(cms => { let comment = cms.map(obj => ({ ...obj, to_content_type: CommentType.COMMENT_TYPE_SERIE, to_object_id: obj.to_object_id, to_content_object: `{ Serie : ${obj.to_object_id}}` })); this._comments.next(comment) });
        this.object_id = this.serie_id;
      }
    }
    else if (this.comment_type == 'episode') {
      if (this.episode_id) {
        this.logger.debug('ngOInit comments for episodeid ' + this.episode_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        this.comments$ = this._comments.asObservable();
        this.getEpisodeComments(this.episode_id).subscribe(cms => { let comment = cms.map(obj => ({ ...obj, to_content_type: CommentType.COMMENT_TYPE_EPISODE, to_object_id: obj.to_object_id, to_content_object: `{ Episode : ${obj.to_object_id}}` })); this._comments.next(comment) });
        this.object_id = this.episode_id;
      }
    }
    else if (this.comment_type == 'photo') {
      if (this.photo_id) {
        this.logger.debug('ngOInit comments for episodeid ' + this.photo_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        this.comments$ = this._comments.asObservable();
        this.getPhotoComments(this.photo_id).subscribe(cms => { let comment = cms.map(obj => ({ ...obj, to_content_type: CommentType.COMMENT_TYPE_PHOTO })); this._comments.next(comment) });
        this.object_id = this.photo_id;
      }
    }
    else if (this.comment_type == 'comment') {
      if (this.comment_id) {
        this.logger.debug('ngOInit comments for episodeid ' + this.photo_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        this.comments$ = this._comments.asObservable();
        this.getCommentComments(this.comment_id).subscribe(cms => { let comment = cms.map(obj => ({ ...obj, to_content_type: CommentType.COMMENT_TYPE_COMMENT })); this._comments.next(comment) });
        this.object_id = this.comment_id;
      }
    }
    else if (this.comment_type == 'user') {
      if (this.user_id) {
        this.logger.debug('ngOInit comments for episodeid ' + this.photo_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        // this.comments$ = this.getEpisodeComments(this.episode_id);
        this.comments$ = this._comments.asObservable();
        this.getUserComments(this.user_id).subscribe(cms => { let comment = cms.map(obj => ({ ...obj, to_content_type: CommentType.COMMENT_TYPE_USER })); this._comments.next(comment) });
        this.object_id = this.user_id;
      }
    }
    else
      this.logger.debug('comments component wrong component type');

    this.selected = false;
    this.logger.debug('ngOInit comments end ');

    //   this.postId = this.route.snapshot.params.id;
    //   this.commentService.getComments(this.postId);
    //   this.commentSub = this.commentService.getCommentUpdateListener()
    //     .subscribe((commentList: Comment[]) => {
    //       this.comments = commentList;
    //     });
    // 
  }
  newcomment(comment: Comment) {
    this.logger.debug('notified of new comment' + JSON.stringify(comment));
    let newval: Comment[];
    // this._comments.value.push(comment);
    const curcomments = this._comments.getValue();
    const newcomments = curcomments.slice(0);
    newcomments.unshift(comment);
    this._comments.next(newcomments);

    // this._comments.value.unshift(comment);
    // this._comments.next(this._comments.value);
    //.push(comment));
    //    this._todos.next(this._todos.getValue().push(newTodo));

    //   this.comments$.pipe(map(commentlist => { commentlist.push(comment); return commentlist; }));
    console.log('notified done new ');
  }


  getSerieComments(serie_id: number): Observable<Comment[]> {
    this.logger.debug('getSerieComments ');
    return (this.serieService.getSerieComments(serie_id).pipe(map(comments => comments.map(comment => { comment.pub_date = new Date(comment.pub_date); return comment }))));
  }

  getEpisodeComments(episode_id: number): Observable<Comment[]> {
    this.logger.debug('getEpisodeComments ');
    return (this.episodeService.getEpisodeComments(episode_id).pipe(map(comments => comments.map(comment => { comment.pub_date = new Date(comment.pub_date); return comment }))));
  }

  getPhotoComments(photo_id: number): Observable<Comment[]> {
    this.logger.debug('getPhotoComments ');
    return (this.photoService.getPhotoComments(photo_id).pipe(map(comments => comments.map(comment => { comment.pub_date = new Date(comment.pub_date); return comment }))));
  }

  getCommentComments(comment_id: number): Observable<Comment[]> {
    this.logger.debug('getPhotoComments ');
    return (this.commentService.getCommentComments(comment_id).pipe(map(comments => comments.map(comment => { comment.pub_date = new Date(comment.pub_date); return comment }))));
  }

  getUserComments(user_id: number): Observable<Comment[]> {
    this.logger.debug('getUserComments ');
    return (this.userService.getUserComments(user_id).pipe(map(comments => comments.map(comment => { comment.pub_date = new Date(comment.pub_date); return comment }))));
  }

  getMyComments(user__id: number): Observable<Comment[]> {
    this.logger.debug('get my Comments ');
    return (this.commentService.getMyComments());
    //   .subscribe(page => {
    //   this.comments = page.results;
    // });
  }

  // // from ngx-sortable
  // public selectItem(item: any) {
  //   this.selectedItem = item;
  //   this.selected = true;
  //   this.textSelected.emit(this.selectedItem);

  // }

  // getComments(): void {
  //   this.commentService.getTextesListAll().subscribe(page => this.comments = page.results);
  // }

  // add(name: string): void {
  //   name = name.trim();
  //   if (!name) { return; }
  //   this.commentService.addComment({ texte: name } as Texte)
  //     .subscribe(hero => {
  //       this.comments.push(hero);
  //     });
  // }

  // delete(texte: Texte): void {
  //   this.comments = this.comments.filter(h => h !== texte);
  //   this.commentService.deleteComment(texte).subscribe();
  // }
  // select(texte: Texte): void {
  // }


  onDeleteComment(id) {
    this.commentService.deleteComment(id);
  }

  onReply() {
    this.showReply = (!this.showReply) ? true : false;
  }
  ngOnDestroy() {
    // this.logger.debug('ngOcwDestroy comments for episodeid ' + this.episode_id)

    //  this.commentSub.unsubscribe();
  }
}
