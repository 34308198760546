import { Texte } from '@/models/texte';
import { PhotoService } from '@/services/photo.service';
import { TexteService } from '@/services/texte.service';
import { randomInt } from '@/shared/util';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-new-text-dialog',
    templateUrl: './new-text-dialog.component.html',
    styleUrls: ['./new-text-dialog.component.scss'],
    standalone: false
})
export class NewTextDialogComponent implements OnInit {

  // @Input() photo_id: number;
  texteForm: FormGroup;
  // @Output() newTexte = new EventEmitter<Texte>();
  description: string;
  photo_id: number;

  constructor(
    private dialogRef: MatDialogRef<NewTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private texteService: TexteService,
    private logger: NGXLogger
  ) {
    this.description = data.description;
    this.logger.debug('newTextDialog constructor ' + JSON.stringify(data));
    this.photo_id = data.id;
  }

  ngOnInit(): void {
    this.texteForm = this.fb.group({
      'texte': ['', Validators.required],
      'type': [1, Validators.required],
      'shape': [1, Validators.required],
      'font_size': ['0.8rem', Validators.required],
      'background_color': ['rgba(255, 248, 220, 1.0)', Validators.required],
    });
  }

  onSubmit() {
    const formValue = this.texteForm.value;
    const newTexte = new Texte();
    console.log('you submitted value: ', formValue);
    newTexte.texte = formValue['texte'];
    newTexte.type = formValue['type'];
    newTexte.font_size = formValue['font_size'];
    newTexte.background_color = formValue['background_color'];
    // newTexte.photo = this.photo_id;
    newTexte.position_x = 250 + randomInt(1, 90);
    newTexte.position_y = 250 + randomInt(1, 90);
    newTexte.arrow_x = 90 + randomInt(1, 90);
    newTexte.arrow_y = 90 + randomInt(1, 90);


    console.log('texteform newtexte: ', newTexte.texte);
    this.texteService.addTexte(newTexte)
      .subscribe(txt => {
        console.log('add_parole txt :' + txt.texte);
        console.log('add_parole id :' + txt.id);
        // this.newTexte.emit(txt)
        // return txt.id;
      });
  }
  save() {
    const formValue = this.texteForm.value;
    const newTexte = new Texte();
    console.log('you submitted value: ', formValue);
    newTexte.texte = formValue['texte'];
    newTexte.type = formValue['type'];
    newTexte.font_size = formValue['font_size'];
    newTexte.background_color = formValue['background_color'];
    newTexte.photo = this.photo_id;
    newTexte.shape = formValue['shape'];
    newTexte.position_x = 250 + randomInt(1, 100);
    newTexte.position_y = 250 + randomInt(1, 100);
    newTexte.arrow_x = 90 + randomInt(1, 150);
    newTexte.arrow_y = 90 + randomInt(1, 150);


    console.log('texteform newtexte: ', newTexte.texte);
    this.texteService.addTexte(newTexte)
      .subscribe(txt => {
        console.log('add_parole txt :' + txt.texte);
        console.log('add_parole id :' + txt.id);
        // this.newTexte.emit(txt)
        // return txt.id;
        this.dialogRef.close(txt);
      });
    // this.dialogRef.close(newTexte);
    // this.dialogRef.close(this.texteForm.value);
  }

  close() {
    this.dialogRef.close();
  }
}
