import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Episode } from '../models/episode';
import { EpisodeService } from '../services/episode.service';
import { FileUploader } from 'ng2-file-upload';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';


// const URL = '/api/';
const URL = 'http://localhost:8000/en/api/images/';

export interface Headers {
  name: string;
  value: string;
}
const headers1 = [{ 'Content-Type': 'application/json' }, { 'Authorization': 'Basic ' + btoa('admin1:manager12') }];

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('admin1:manager12') })
};


@Component({
    selector: 'app-episode-edit',
    templateUrl: './episode-edit.component.html',
    styleUrls: ['./episode-edit.component.scss'],
    standalone: false
})
export class EpisodeEditComponent implements OnInit {

  @Input() episode: Episode;
  @Input() uploader: FileUploader = new FileUploader({
    url: URL,
    // allowedMimeType: ['image/png', 'image/jpeg'],
    //  Content-Type: 'image/png',
    headers:
      [
        { name: 'Authorization', value: 'Basic ' + btoa('admin1:manager12') }
      ]
  });

  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  constructor(
    private route: ActivatedRoute,
    private episodeService: EpisodeService,
    private location: Location,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.getEpisode();
  }

  getEpisode(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.logger.debug('constructor episodeedit id :  ' + id);
    this.episodeService.getEpisode(id)
      .subscribe(episode => this.episode = episode);
  }

}
