import { NewTextDialogComponent } from '@/edit-photo/new-text-dialog/new-text-dialog.component';
import { Serie } from '@/models/serie';
import { SerieService } from '@/services/serie.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-delete-serie-dialog',
    templateUrl: './delete-serie-dialog.component.html',
    styleUrls: ['./delete-serie-dialog.component.scss'],
    standalone: false
})
export class DeleteSerieDialogComponent {


  serie_id: number;

  constructor(
    private dialogRef: MatDialogRef<NewTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private serieService: SerieService,
    private logger: NGXLogger
  ) {
    this.logger.debug('DeletePhotoDialogComponent constructor ' + JSON.stringify(data));
    this.serie_id = data.id;
  }

  ngOnInit(): void {
  }

  save() {
    const newSerie = new Serie();
    newSerie.id = this.serie_id;
    this.serieService.deleteSerie(newSerie)
      .subscribe(txt => {
        console.log('delete txt :' + newSerie.id);
        // this.newTexte.emit(txt)
        // return txt.id;
      });
    this.dialogRef.close('deleted');
  }

  close() {
    this.dialogRef.close();
  }
}
