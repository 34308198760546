import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorMessage } from '../models/error-message';
import { tap } from 'rxjs/operators';
import { ErrorMessagesService } from './error-messages.service';

@Component({
    selector: 'errormessages',
    templateUrl: './error-messages.component.html',
    styleUrls: ['./error-messages.component.css'],
    standalone: false
})
export class ErrorMessagesComponent implements OnInit {

  showMessages = false;

  errors$: Observable<string[]>;


  constructor(public errormessagesService: ErrorMessagesService) {

    console.log("Created messages component");

  }

  ngOnInit() {
    this.errors$ = this.errormessagesService.errors$
      .pipe(
        tap(() => this.showMessages = true)
      );

  }


  onClose() {
    this.showMessages = false;

  }

}
