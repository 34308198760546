import { Component, Injectable, OnInit, TemplateRef, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, AlertService } from '@/_services';
import { first } from 'rxjs/operators';
import { ResetPasswordDoneDialogComponent } from './reset-password-done-dialog/reset-password-done-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: false
})

export class ResetPasswordComponent implements OnInit {
  resetpasswordForm: FormGroup;

  protected disabled: boolean;
  protected error: string;
  protected posting: boolean;
  reset: boolean;
  sent = false;
  verifying: boolean;
  protected verified: boolean;
  verificationFailed: boolean;
  protected uid: string;
  protected resetPasswordToken: string;
  hide = true;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private alertService: AlertService) {
  }

  ngOnInit(): void {


    this.verifying = false;
    this.verified = false;
    this.verificationFailed = false;


    this.uid = this.route.snapshot.paramMap.get('uid');
    this.resetPasswordToken = this.route.snapshot.paramMap.get('resetPasswordToken');
    console.log('ngOnInit ResetPasswordComponent uid ' + this.uid);
    console.log('ngOnInit ResetPasswordComponent uid ' + this.resetPasswordToken);

    console.log('ngOnInit ResetPasswordComponent');

    this.resetpasswordForm = this.formBuilder.group({
      password1: ['', Validators.required],
      password2: ['', Validators.required],

    });
    console.log('ngOnInit ResetPasswordComponent end');

  }


  onSubmit(): void {
    console.log('submit ResetPasswordComponent');
    // stop here if form is invalid
    if (this.resetpasswordForm.invalid) {
      console.log('submit ResetPasswordComponent error');

      return;
    }
    this.verifying = true;
    const password1: string = this.resetpasswordForm.value['password1'];
    const password2: string = this.resetpasswordForm.value['password2'];
    this.authenticationService.passwordResetConfirm(this.uid, this.resetPasswordToken, password1, password2)
      .pipe(first())
      .subscribe(
        data => {
          console.log('ResetPasswordComponent onSubmit loginForm called authenticationService.data' + data);
          this.verifying = false;
          this.verifying = true;
          this.openDialog();
          this.router.navigate(['/home']);
        },
        error => {
          this.verifying = false;
          this.verified = false;
          console.log('error ResetPasswordComponent called authenticationService.login');
          this.alertService.error(error);
        });
    return;

  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ResetPasswordDoneDialogComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

