import { TexteService } from '@/services/texte.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { data } from 'jquery';
import { NGXLogger } from 'ngx-logger';
import { NewTextDialogComponent } from '../new-text-dialog/new-text-dialog.component';

@Component({
    selector: 'app-need-text-selected-dialog',
    templateUrl: './need-text-selected-dialog.component.html',
    styleUrls: ['./need-text-selected-dialog.component.scss'],
    standalone: false
})
export class NeedTextSelectedDialogComponent {
  action: string;

  constructor(
    private dialogRef: MatDialogRef<NeedTextSelectedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private logger: NGXLogger
  ) {
    this.logger.debug('deleteTextDialog constructor ' + JSON.stringify(data));
    this.action = data.action;
  }

  close() {
    this.dialogRef.close();
  }

}
