import { Component, Inject } from '@angular/core';
import { NewTextDialogComponent } from '@/edit-photo/new-text-dialog/new-text-dialog.component';
import { Episode } from '@/models/episode';
import { EpisodeService } from '@/services/episode.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-cgu-dialog',
    templateUrl: './cgu-dialog.component.html',
    styleUrls: ['./cgu-dialog.component.scss'],
    standalone: false
})
export class CguDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<NewTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private episodeService: EpisodeService,
    private logger: NGXLogger
  ) {
    this.logger.debug('CguDialogComponent constructor ');
  }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close('accepted');
  }

  close() {
    this.dialogRef.close('refused');
  }
}
