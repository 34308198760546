import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Photo } from '../models/photo';
import { PhotoService } from '../services/photo.service';
import { Router } from '@angular/router';
import { ImageService } from '@/services/image.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { DeletePhotoDialogComponent } from '@/delete-photo-dialog/delete-photo-dialog.component';
import { AuthenticationService } from '@/_services';

@Component({
    selector: 'app-photo-detail',
    templateUrl: './photo-detail.component.html',
    styleUrls: ['./photo-detail.component.scss'],
    standalone: false
})
export class PhotoDetailComponent implements OnInit {

  @Input() photo: Photo;

  constructor(
    private route: ActivatedRoute,
    public authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private photoService: PhotoService,
    private imageService: ImageService,
    private location: Location,
    private logger: NGXLogger,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getPhoto();
  }

  getPhoto(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.photoService.getPhoto(id)
      .subscribe(photo => this.photo = photo);
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.photoService.updatePhoto(this.photo)
      .subscribe(() => this.goBack());
  }

  edit(photo: Photo): void {
    // this.router.navigateByUrl('/edit_photos/12');
    this.router.navigateByUrl('/edit_photos/' + photo.id);

  }


  like(photo: Photo): void {
    //  this.photoService.likePhoto(photo).subscribe();
  }

  comment(photo: Photo): void {
    //  this.photoService.likePhoto(photo).subscribe();
  }


  deletePhotoDialog(photo_id: number): void {
    this.logger.debug('deletePhotoDialog ' + photo_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: photo_id,
    };
    const dialogRef = this.dialog.open(DeletePhotoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.goBack();
    });
  }
}
