import { Texte } from '@/models/texte';
import { TexteService } from '@/services/texte.service';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { NewTextDialogComponent } from '../new-text-dialog/new-text-dialog.component';

@Component({
    selector: 'app-text-shape-dialog',
    templateUrl: './text-shape-dialog.component.html',
    styleUrls: ['./text-shape-dialog.component.scss'],
    standalone: false
})
export class TextShapeDialogComponent {

  texteForm: FormGroup;
  description: string;
  text_id: number;
  photo_id: number;

  constructor(
    private dialogRef: MatDialogRef<NewTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private texteService: TexteService,
    private logger: NGXLogger
  ) {
    this.logger.debug('newTextDialog constructor ' + JSON.stringify(data));
    this.text_id = data.text_id;
    this.photo_id = data.id;
  }

  ngOnInit(): void {
    this.texteForm = this.fb.group({
      'shape': [1, Validators.required],
    });
  }

  save() {
    const formValue = this.texteForm.value;
    const newTexte = new Texte();
    console.log('you submitted value: ', formValue);
    newTexte.id = this.text_id;
    newTexte.photo = this.photo_id;
    newTexte.shape = formValue['shape'];

    console.log('texteform newtexte: ', newTexte.texte);
    this.texteService.partialUpdateTexte(newTexte)
      .subscribe(txt => {
        console.log('add_parole txt :' + txt.texte);
        console.log('add_parole id :' + txt.id);
        // this.newTexte.emit(txt)
        // return txt.id;
      });
    this.dialogRef.close(this.texteForm.value);
  }

  close() {
    this.dialogRef.close();
  }
}
