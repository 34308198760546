import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../serie-detail.component';

@Component({
    selector: 'app-sharing-dialog',
    templateUrl: './sharing-dialog.component.html',
    styleUrls: ['./sharing-dialog.component.scss'],
    standalone: false
})
export class SharingDialogComponent implements OnInit {

  sharedurl: string;



  constructor(public dialogRef: MatDialogRef<SharingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit(): void {

    this.sharedurl = window.location.href;
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}