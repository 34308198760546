import { Component } from '@angular/core';

@Component({
    selector: 'app-page404',
    imports: [],
    templateUrl: './page404.component.html',
    styleUrl: './page404.component.scss'
})
export class Page404Component {

}
