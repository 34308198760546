import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
// import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Renderer2 } from '@angular/core';
import { Photo } from '../models/photo';
import { PhotoService } from '../services/photo.service';
import { ImageService } from '@/services/image.service';
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { TexteService } from '@/services/texte.service';
import { Texte, TexteShapeType, TexteType } from '@/models/texte';
import { Page } from '@/pagination';
import { SwiperComponent } from 'swiper/angular';
// import { SwiperComponent } from 'swiper/types';
import { createSpeakLine, createThoughtLine, computeArrowPoints, hello, createEllipse } from '../shared/util';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
    selector: 'app-carousssel',
    templateUrl: './carousssel.component.html',
    styleUrls: ['./carousssel.component.scss'],
    standalone: false
})


export class CaroussselComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('myImg', { static: false }) myImg: ElementRef;
  @Input() episode_id: number;
  photos: Photo[];
  fullscreenSubscription: Subscription;
  fullscreen: boolean;
  showText = true;


  showNavigationArrows = true;
  showNavigationIndicators = true;
  images: Array<string> = ['http://127.0.0.1:8000/media/photos/2018/07/29/18577_nizza_le_grotte.jpg',
    'http://127.0.0.1:8000/media/photos/2018/07/29/4006l-cygne-tubercule-cygnus-olor.jpg',
    'http://127.0.0.1:8000/media/photos/2018/07/29/1bistrot240.jpg'
  ];
  // private imgratio: number;
  // private svg;

  constructor(
    // config: NgbCarouselConfig,
    private _http: HttpClient,
    private ref: ChangeDetectorRef,
    private imageService: ImageService,
    private photoService: PhotoService,
    private texteService: TexteService,
    private logger: NGXLogger,
    private render: Renderer2) {
    // customize default values of carousels used by this component tree
    //   config.showNavigationArrows = true;
    //   config.showNavigationIndicators = false;
    //   config.interval = 0;
    //   config.wrap = true;
    //   config.keyboard = true;
    //   config.pauseOnHover = true;
  }

  ngOnInit() {
    // this.logger.debug('carrousesel : ', hello());
    this.getEpisodePhotos(this.episode_id).subscribe(page => {
      this.photos = page;

      //    this.images[1] = 'http://127.0.0.1:8000/media/photos/2018/07/29/4006l-cygne-tubercule-cygnus-olor.jpg';
      //    this._http.get('https://picsum.photos/list')
      //      .pipe(map((images: Array<{ id: number }>) => this._randomImageUrls(images)))
      //     .subscribe(images => this.images = images);
      this.fullscreenSubscription = this.photoService.fullscreen.subscribe(fs => {
        // this.ref.markForCheck();
        // this.logger.debug('episode component changefullscreen value  ' + fs);
        this.fullscreen = fs;
        // this.ref.marpkForCheck()i
        this.ref.detectChanges();
        // this.logger.debug('episode component changefullscreen value 2 ' + fs);
      });
      // this.logger.debug('carrousel pphotos length:' + this.photos.length);
      for (let photo of this.photos) {
        // this.logger.debug('carrousesel photoid: ', photo.id);
        this.texteService.getPhotoTextes(photo.id).subscribe(y => {
          // this.imgratio = this.myImg.nativeElement.clientWidth / this.myImg.nativeElement.naturalWidth;
          // this.logger.debug('carrousel imgratio : ' + this.imgratio);
          // this.logger.debug('carrousel clientw nat w : ' +
          // this.myImg.nativeElement.clientWidth + ' , ' + this.myImg.nativeElement.naturalWidth);
          for (const txt of y) {
            // this.logger.debug('carrousesel txt: ', txt.texte);
            this.drawTexte(photo.id, photo.size_x, txt);
          }
        });
      }
    });
  }

  toggleText() {
    // this.logger.debug('onslide change togletext activeindex: ' + this.swiper.swiperRef.activeIndex);
    // this.logger.debug('carrousesel togletxt: photo id : ' + this.photos[this.swiper.swiperRef.activeIndex].id);
    this.showText = !this.showText;
    const textImg = document.getElementById('textimg' +
      this.photos[this.swiper.swiperRef.activeIndex].id
    ) as HTMLElement;
    const svgImg = document.getElementById('svg' +
      this.photos[this.swiper.swiperRef.activeIndex].id
    ) as HTMLElement;
    if (!this.showText) {
      textImg.style.visibility = 'hidden';
      svgImg.style.visibility = 'hidden';
    } else {
      textImg.style.visibility = 'visible';
      svgImg.style.visibility = 'visible';
    }
  }

  getEpisodePhotos(episode_id: number): Observable<Photo[]> {
    // this.photoService.getEpisodePhotos(episode_id).subscribe(page => this.photos = page.results);
    return this.photoService.getEpisodePhotos(episode_id);
  }

  private _randomImageUrls(images: Array<{ id: number }>): Array<string> {
    return [1, 2].map(() => {
      const randomId = images[Math.floor(Math.random() * images.length)].id;
      return `https://picsum.photos/900/500?image=${randomId}`;
    });
  }

  onReachEnd(swiper) {
    // this.logger.debug('reach end');
    // this.logger.debug(swiper);
  }
  onSwiper(swiper) {
    // this.logger.debug('onSwiper');
    // this.logger.debug(swiper);

  }
  noFullscreen() {
    this.logger.debug('noFullscreen');
    this.photoService.changeFullscreen(false);
  }

  onTap() {
    // this.logger.debug('tapped');
    // this.photoService.changeFullscreen(true);
    this.photoService.toggleFullscreen(true);
  }
  onClick() {
    // this.logger.debug('clicked');
    this.photoService.changeFullscreen(true);
  }

  onSlideChange() {
    // this.logger.debug('slide change');
    // this.logger.debug('onslide change activeindex: ' + this.swiper.swiperRef.activeIndex);
    // this.logger.debug('onslide change realindex: ' + this.swiper.swiperRef.realIndex);


  }

  createSVG(id) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg',
      'svg');
    svg.setAttribute('id', 'svg' + id);
    svg.setAttribute('style', 'position:absolute;top:0px;left:0px');
    svg.setAttribute('width', String(this.myImg.nativeElement.clientWidth));
    svg.setAttribute('height', String(this.myImg.nativeElement.clientHeight));
    svg.style.zIndex = '9';
    svg.setAttributeNS("http://www.w3.org/2000/xmlns/",
      "xmlns:xlink",
      "http://www.w3.org/1999/xlink");
    // document.body.appendChild(svg);
    return svg;
  }

  drawTexte(photoid: number, size_x: number, txt: Texte) {
    const logger = this.logger;
    // this.logger.debug('drawTexte txt id  ' + txt.id);
    let svg: SVGSVGElement;
    let imgratio: number;

    const myImg = document.getElementById('myimg' + photoid) as HTMLImageElement;;
    imgratio = myImg.clientWidth / myImg.naturalWidth;
    this.logger.debug('drawtext imgratio =' + String(imgratio));



    const textImg = document.getElementById('textimg' + photoid) as HTMLImageElement;;
    const textDiv = document.createElement('div');
    textImg.appendChild(textDiv);
    textDiv.setAttribute('draggable', 'false');
    textDiv.setAttribute('data-textid', String(txt.id));
    // textDiv.classList.add('text-block');
    this.render.addClass(textDiv, 'text-block');

    // textDiv.style.top = String(Math.floor(txt.position_y * imgratio)) + 'px';
    // textDiv.style.left = String(Math.floor(txt.position_x * imgratio)) + 'px';
    let textDivtop = Math.floor(txt.position_y * imgratio);
    let textDivleft = Math.floor(txt.position_x * imgratio);
    // this.logger.debug(' textdiv top =' + textDiv.style.top);
    // this.logger.debug(' teYxtdiv left =' + textDiv.style.left);
    textDiv.style.backgroundColor = 'rgba(255,248,220,0.7)';
    // textDiv.style.backgroundColor = 'Cornsilk';
    textDiv.style.whiteSpace = 'pre';
    textDiv.style.zIndex = '10';
    textDiv.style.position = 'absolute';
    textDiv.style.borderRadius = '7px';
    // textDiv.style.boxSizing = 'border-box';
    textDiv.style.padding = '7px';
    textDiv.style.border = '1px solid';
    textDiv.id = String(txt.id);
    // this.logger.debug('drawTexte textDiv id  ' + textDiv.id);
    // this.logger.debug('drawTexte textDiv pos  top: ' + textDiv.style.top + ' , left: ' + textDiv.style.left);
    const textContent = document.createTextNode(txt.texte);
    textDiv.appendChild(textContent);

    let bbox = textDiv.getBoundingClientRect();
    // this.logger.debug('drawTexte textDiv bbox : ' + JSON.stringify(bbox));
    const PADDING = 0;
    const boundaryX1 = 0 + PADDING;
    const boundaryX2 = myImg.clientWidth - PADDING;
    const boundaryY1 = 0 + PADDING;
    const boundaryY2 = myImg.clientHeight - PADDING;
    // this.logger.debug('drawTexte boundary x2 y2 : ' + boundaryX2 + ' ' + boundaryY2);
    let minX = boundaryX1;
    let maxX = boundaryX2 - bbox.width;
    let minY = boundaryY1;
    let maxY = boundaryY2 - bbox.height;
    // let dy = textDiv.offsetTop;
    // let dx = textDiv.offsetLeft;
    let dy = textDivtop;
    let dx = textDivleft;
    if (dx < minX) {
      dx = minX;
      // logger.debug('dx constrained ' + dx);
    } else if (dx > maxX) {
      dx = maxX;
      // logger.debug('dx constrained ' + dx);
    }
    if (dy < minY) {
      dy = minY;
      // logger.debug('dy constrained ' + dy);
    } else if (dy > maxY) {
      dy = maxY;
      // logger.debug('dy constrained ' + dy);
    }
    // logger.debug('dx dy last ' + dx + ' ' + dy);
    textDiv.style.top = String(dy) + 'px';
    textDiv.style.left = String(dx) + 'px';

    svg = null;
    svg = <SVGSVGElement><any>document.getElementById('svg' + photoid);

    const startxl = txt.arrow_x * imgratio;
    const startyl = txt.arrow_y * imgratio;
    const arrowPoints = computeArrowPoints(logger, textDiv.offsetLeft, textDiv.offsetTop,
      textDiv.offsetWidth, textDiv.offsetHeight,
      txt.arrow_x * imgratio, txt.arrow_y * imgratio);
    // logger.debug('startxl yl :' + startxl + ' , ' + startyl);
    // logger.debug('arrow points :' + JSON.stringify(arrowPoints));
    // logger.debug('txt :' + txt.type);

    if (txt.type === TexteType.parole) {
      createThoughtLine(logger, svg, imgratio, txt.id, txt.background_color, startxl, startyl, arrowPoints.x1, arrowPoints.y1);
    } else if (txt.type === TexteType.pensee) {
      createSpeakLine(logger, svg, imgratio, txt.id, txt.background_color, startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2);
    }

    if (txt.shape === TexteShapeType.ovale) {
      textDiv.style.backgroundColor = 'rgba(255,248,220,0.1)';
      textDiv.style.border = '0px solid';
      createEllipse(logger, svg, imgratio, txt.id, txt.background_color,
        textDiv.offsetLeft, textDiv.offsetTop, textDiv.offsetWidth, textDiv.offsetHeight);
    }
  }
}
