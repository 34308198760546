import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class NewstosubscriberService {

  constructor(private http: HttpClient, private urlService: UrlService) {

  }

  addPushSubscriber(sub: any) {
    console.log('NewstosubscriberService addPushSubscriber')
    return this.http.post(this.urlService.getSaveWPSubscriptionUrl(), JSON.stringify(sub));
  }


  removeSubscriber(sub: any) {
    console.log('NewstosubscriberService removeSubscriber')
    return this.http.post(this.urlService.getSaveWPSubscriptionUrl(), JSON.stringify(sub));
  }

  send() {
    return this.http.post('/api/newsletter', null);
  }

}

