import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentService } from '../services/comment.service';
import { Comment } from '../models/comment';
import { Route, ActivatedRoute } from '@angular/router';
import { User } from '@/_models';
import { Observable } from 'rxjs';
import { ImageService } from '@/services/image.service';
import { LeaseDurationType } from '@azure/storage-blob';


@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    standalone: false
})

export class CommentComponent implements OnInit {
  @Input() comment: Comment;
  writer: Observable<User>;
  sincedur: String;
  display_comment: boolean = true;

  islikedbyme: boolean;
  iscommentedbyme: boolean;
  nblikes: number;
  nbcomments: number;

  constructor(
    private commentService: CommentService,
    private imageService: ImageService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    var Duration = require("duration");
    let sincedurt = new Duration(new Date(this.comment.pub_date)).toString(1, 1);
    console.log('sinedur : ' + sincedurt);
    console.log(' val 2 de sincedur ' + sincedurt[2]);
    console.log(' val 2 de sincedur ' + JSON.stringify(sincedurt));
    this.sincedur = sincedurt.trim().split(' ')[0];
    console.log('CommentComponent ' + JSON.stringify(this.comment));
    this.writer = this.commentService.getcommentwriter(this.comment.id)

    this.commentService.islikedbyme(this.comment.id).subscribe(data => this.islikedbyme = data);
    this.commentService.iscommentedbyme(this.comment.id).subscribe(data => this.iscommentedbyme = data);
    this.commentService.nblikes(this.comment.id).subscribe(data => this.nblikes = data);
    this.commentService.nbcomments(this.comment.id).subscribe(data => this.nbcomments = data);
  }

  likecomment(comment: Comment): void {
    this.commentService.likeComment(comment).subscribe();
    this.islikedbyme = !this.islikedbyme;
    if (this.islikedbyme)
      this.nblikes += 1;
    else
      this.nblikes -= 1;
  }

}
