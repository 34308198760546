import { Component, OnInit } from '@angular/core';
import {
  SocialAuthService, SocialAuthServiceConfig
} from '@abacritt/angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { SocialUser } from '@abacritt/angularx-social-login';


@Component({
    selector: 'app-demo',
    templateUrl: './sociallogin.component.html',
    styleUrls: ['./sociallogin.component.scss'],
    standalone: false
})
export class SocialloginComponent implements OnInit {


  user: SocialUser;
  loggedIn: boolean;

  constructor(private authService: SocialAuthService) { }


  ngOnInit() {
    // const fbLoginOptions = {
    //   scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
    //   return_scopes: true,
    //   enable_profile_selector: true
    // }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

    // const googleLoginOptions = {
    //   scope: 'profile email'
    // }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

    // const vkLoginOptions = {
    //   fields: 'photo_max,contacts', // Profile fields to return, see: https://vk.com/dev/objects/user
    //   version: '5.124', // https://vk.com/dev/versions
    // }; // https://vk.com/dev/users.get

    // let config = new AuthServiceConfig([
    //   {
    //     id: GoogleLoginProvider.PROVIDER_ID,
    //     provider: new GoogleLoginProvider('Google-OAuth-Client-Id', googleLoginOptions)
    //   },
    //   {
    //     id: FacebookLoginProvider.PROVIDER_ID,
    //     provider: new FacebookLoginProvider('Facebook-App-Id', fbLoginOptions)
    //   },
    //   // {
    //   //   id: VKLoginProvider.PROVIDER_ID,
    //   //   provider: new VKLoginProvider("VK-App-Id", vkLoginOptions)
    //   // },
    // ]);
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    // const fbLoginOptions = {
    //   scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages'
    // }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions);

    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

}
