import { AuthenticationService } from '@/_services';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { BrowserStorageService } from '@/services/browserstorage.service';


@Component({
    selector: 'app-language-dialog',
    templateUrl: './language-dialog.component.html',
    styleUrls: ['./language-dialog.component.scss'],
    standalone: false
})
export class LanguageDialogComponent {
  texteForm: FormGroup;
  currentLanguage: string;

  constructor(
    private dialogRef: MatDialogRef<LanguageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private browserStorageService: BrowserStorageService,
    private logger: NGXLogger
  ) {
    this.logger.debug('LanguageDialogComponent constructor ' + JSON.stringify(data));
  }

  ngOnInit(): void {
    this.currentLanguage = this.getCurrentlanguage();
    this.texteForm = this.fb.group({
      'lang': ['fr', Validators.required],
    });
  }

  save() {
    const formValue = this.texteForm.value;
    console.log('you submitted value: ', formValue);
    console.log('save currentLanguage : ', this.currentLanguage);
    let language = (this.currentLanguage == 'zh') ? 'zh-hans' : this.currentLanguage;
    this.translate.use(language)
    this.translate.setDefaultLang(language) // why change default
    this.browserStorageService.setItem('locale', language)
    this.dialogRef.close(this.texteForm.value);
  }

  close() {
    this.dialogRef.close();
  }

  getLanguages(): string[] {
    return this.translate.getLangs().map((lg) => { if (lg == 'zh-hans') { return 'zh' } else return lg });
  }

  getCurrentlanguage(): string {
    return (this.translate.currentLang == 'zh-hans') ? 'zh' : this.translate.currentLang;
  }
  changeLang(lg: string) {
    console.log('changeLang lg : ', lg);
    console.log('changeLang currentLanguage : ', this.currentLanguage);
    let language = (lg == 'zh') ? 'zh-hans' : lg;
    this.translate.use(language)
    this.translate.setDefaultLang(language) // why change default
    this.browserStorageService.setItem('locale', language)
  }


}
