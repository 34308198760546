import { Component, Input, OnInit } from '@angular/core';
import { Episode } from '../models/episode';
import { LikeService } from '../services/like.service';
import { FormControl, FormGroup } from '@angular/forms';
import { map, Observable, Subject } from 'rxjs';
import { Page } from '../pagination';
import { ImageService } from '@/services/image.service';
import { AuthenticationService } from '@/_services';
import { CommentService } from '@/services/comment.service';
import { CommentEpisodeService } from '@/services/commentepisode.service';
import { CommentPhotoService } from '@/services/commentphoto.service';
import { CommentSerieService } from '@/services/commentserie.service';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { EpisodeService } from '@/services/episode.service';
import { Serie } from '@/models/serie';
import { Photo } from '@/models/photo';
import { SerieService } from '@/services/serie.service';
import { PhotoService } from '@/services/photo.service';


@Component({
    selector: 'app-shares',
    templateUrl: './shares.component.html',
    styleUrls: ['./shares.component.scss'],
    standalone: false
})
export class SharesComponent implements OnInit {
  @Input() owner_id: number;
  @Input() object_id: number;
  @Input() content_type: string;
  episodes: Episode[];
  series$: Observable<Episode[]>;
  episodes$: Observable<Episode[]>;
  photos$: Observable<Episode[]>;
  filterForm: FormGroup;
  // page: Observable<Page<Episode>>;

  constructor(
    private serieService: SerieService,
    private episodeService: EpisodeService,
    private photoService: PhotoService,
    public authenticationService: AuthenticationService,
    private logger: NGXLogger,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.episodes$ = this.getMyLikedEpisodes();
  }
  getMyLikedSeries(): Observable<Serie[]> {
    return this.serieService.getMyLikedSeries();
  }
  getMyLikedEpisodes(): Observable<Episode[]> {
    return this.episodeService.getMyLikedEpisodes();
  }

  getMyLikedPhotos(): Observable<Photo[]> {
    return this.photoService.getMyLikedPhotos();
  }
}

