import { Component, OnInit, ViewChild, Input, ElementRef, Inject, LOCALE_ID } from '@angular/core';
// import { MatDialogRef } from '@angular/material';
import { UploadService } from '../services/upload.service';
import { forkJoin, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Photo } from '@/models/photo';
import { PhotoService } from '@/services/photo.service';
import { MessageService } from '@/services/message.service';
import { NGXLogger } from 'ngx-logger';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '@/_services';
import { formatDate } from '@angular/common';


@Component({
    selector: 'app-telecharge',
    templateUrl: './telecharge.component.html',
    styleUrls: ['./telecharge.component.scss'],
    standalone: false
})
export class TelechargeComponent implements OnInit {
  @ViewChild('fileInput', { read: true, static: true }) fileInput: ElementRef; // sfrezefo modif 7 to 8 ng updat ?
  @Input() episodeId: number;
  @Input() photo_type: string;
  @Input() object_id: string;
  @Input() uuidfname: string;
  @Input() numero: number;
  @Input() thumbnailText: string = 'thumbnail';
  photoId: number;
  progress;
  canBeClosed = true;
  primaryButtonText = 'Upload';
  showCancelButton = true;
  uploading = false;
  uploadProgress: number;
  size_x: number;
  size_y: number;
  preview: string;
  uuidFileName: string;

  uploadSuccessful = false;
  file1: File;
  filename: string;
  // addfiles , upload, 
  stage = 'addfiles';

  public files: Set<File> = new Set();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public dialogRef: MatDialogRef<TelechargeComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private router: Router,
    private route: ActivatedRoute,
    public authenticationService: AuthenticationService,
    public uploadService: UploadService,
    private messageService: MessageService,
    private photoService: PhotoService,
    private logger: NGXLogger
  ) {
    this.episodeId = data.episode_id;
    this.photo_type = data.photo_type;
    this.object_id = data.object_id;
    // this.episodeId = data.episode_id;
  }

  ngOnInit() {
    this.logger.debug('TelechargeComponent dialog ' + this.episodeId);
  }

  addFiles() {
    console.log('  addFiles ');
    this.fileInput.nativeElement.click();
  }

  onFilesAdded(event) {

    const file: File = event.target.files[0];
    if (file) {
      this.filename = file.name;
      // is formData used ??????
      const formData = new FormData();
      formData.append("thumbnail", file);
      // const upload$ = this.http.post("/api/thumbnail-upload", formData);
      // upload$.subscribe();
      this.file1 = file;
      // this.logger.debug('onFileChange this fileName : ', this.fileName);
      const reader = new FileReader();
      reader.onload = (e) => {
        this.preview = reader.result as string;
        // this.logger.debug('onFileChddange this preview : ', this.preview);
        const image = new Image();
        image.src = e.target.result as string;
        image.onload = () => {
          const {
            height,
            width
          } = image;
          this.size_y = height;
          this.size_x = width;
          console.log('height and width : ' + height + ' ' + width)
          return true;
        };
      };


      reader.readAsDataURL(file);
    }

    // what for ???
    for (const key in event.target.files) {
      if (!isNaN(parseInt(key, 10))) {
        this.files.add(event.target.files[key]);
      }
    }

    // console.log('uploadEpisodePhoto onFilesAdded ');
    // const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    // this.file1 = this.fileInput.nativeElement.files[0];
    // console.log('uploadEpisodePhoto onFilesAdded file:' + this.file1);
    // console.log('uploadEpisodePhoto onFilesAdded filename:' + this.file1.name);

    // for (const key in files) {
    //   if (!isNaN(parseInt(key, 10))) {
    //     this.files.add(files[key]);
    //   }
    // }
    this.stage = 'upload';
  }


  cancelDialog() {
    this.logger.debug('upload dialog cancelDialog ');
    this.dialogRef.close();
  }

  finishDialog() {

    if (this.uploadSuccessful) {
      this.logger.debug('upload dialog closeDialog uploadSuccessful true');
      this.dialogRef.close({ photo_id: this.photoId, uuidFileName: this.uuidFileName, size_x: this.size_x, size_y: this.size_y });
    }
  }

  uploadDialog() {
    // start the upload and save the progress map
    // this.progress = 
    // this.uploadService.uploadEpisodePhoto(this.files, 1, 1);
    // const fileURL = this.uploadService.getBaseUrl();
    // to track progress
    // const upload$ = this.http.post("/api/thumbnail-upload", formData, {
    //   reportProgress: true,
    //   observe: 'events'
    // })
    this.uploading = true;
    console.log('uploadEpisodePhoto file1:' + this.file1);
    // this.uuidFileName = this.uploadService.upload(this.file1);
    this.uuidFileName = this.uploadService.uploadWithTags(this.file1,
      {
        "photo_type": this.photo_type,
        "object_id": this.object_id,
        "pseudo": this.authenticationService.currentUserValue.pseudo,
        "user_id": this.authenticationService.currentUserValue.id,
        "date": formatDate(Date.now(), 'yyyyMMddHHmmss', this.locale)
      });
    console.log('uploadEpisodePhoto uuidFileName:' + this.uuidFileName);

    // const photo: Photo = new Photo();
    // photo.desc = 'desccccc ' + this.uuidFileName;
    // photo.numero = this.numero;
    // photo.episode = this.episodeId; // should be parameter for the service
    // // photo.url = 'https://sapicafrou.blob.core.windows.net/uploads/' + fileURL;
    // photo.url = '';
    // photo.photocontainer = 'uploads';
    // photo.photoname = this.uuidFileName;
    // photo.size_x = this.size_x;
    // photo.size_y = this.size_y;


    // this.photoService.addPhoto(photo)
    //   .subscribe(newphoto => {
    //     this.log('uploadService djphoto added id: ' + newphoto.id + ' desc :' + newphoto.desc);
    //     this.photoId = newphoto.id;
    //   });
    // this.log('uploadService djphoto added ' + photo.desc);
    /*
        this.progress = this.uploadService.upload(this.files);
        console.log(this.progress);
        for (const key in this.progress) {
          this.progress[key].progress.subscribe(val => console.log(val));
        }
 
        // convert the progress map into an array
        let allProgressObservables = [];
        for (let key in this.progress) {
          allProgressObservables.push(this.progress[key].progress);
        }
     */
    // Adjust the state variables

    // to have progress bar
    // this.uploadSub = upload$.subscribe(event => {
    //   if (event.type == HttpEventType.UploadProgress) {
    //     this.uploadProgress = Math.round(100 * (event.loaded / event.total));
    //   }
    // })
    // The OK-button should have the text "Finish" now
    this.primaryButtonText = 'Finish';

    // The dialog should not be closed while uploading
    this.canBeClosed = false;
    // this.dialogRef.disableClose = true;

    this.showCancelButton = false;

    /*
        // When all progress-observables are completed...
        forkJoin(allProgressObservables).subscribe(end => {
          // ... the dialog can be closed again...
          this.canBeClosed = true;
          this.dialogRef.disableClose = false;
 
          // ... the upload was successful...
          this.uploadSuccessful = true;
 
          // ... and the component is no longer uploading
          this.uploading = false;
        }); */

    // sfrezefo
    this.canBeClosed = true;
    // this.dialogRef.disableClose = false;
    this.uploadSuccessful = true;
    this.uploading = false;
    // end sfrezefo


    this.stage = 'finish'
  }
  private log(message: string) {
    this.messageService.add('UploadService: ' + message);
  }

}
