export class User {
    public id: number;
    public email: string;
    public password: string;
    public pseudo: string;
    public bio: string;
    public photourl: string;
    public photoname: string;
    public photocontainer: string;
    public name: string;//used ?
    public first_name: string;//used ?
    public last_name: string;//used ?
    public phone: string;
    public city: string;
    public country: string;
    public birth_date: Date;
    public pub_date: Date;
    public token: string;
    public groups: string[];

    constructor(
        id?: number,
        email?: string,
        pseudo?: string,
        token?: string) {
        this.id = id;
        this.email = email;
        this.token = token;
        this.pseudo = pseudo;
    }
}