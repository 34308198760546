import { Texte } from '@/models/texte';
import { TexteService } from '@/services/texte.service';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-text-message-dialog',
    templateUrl: './text-message-dialog.component.html',
    styleUrls: ['./text-message-dialog.component.scss'],
    standalone: false
})
export class TextMessageDialogComponent {

  textMessage: String;

  constructor(
    private dialogRef: MatDialogRef<TextMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private texteService: TexteService,
    private logger: NGXLogger
  ) {
    this.logger.debug('TextMessageDialogComponent constructor ' + JSON.stringify(data));
    this.textMessage = data.textMessage;
  }

  ngOnInit(): void {
  }


  close() {
    this.dialogRef.close();
  }
}
