import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentService } from '../services/comment.service';
import { Comment, CommentType } from '../models/comment';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { setCarretPos, getPos } from '../shared/util';
import { UrlService } from '@/services/url.service';

@Component({
    selector: 'app-comment-form',
    templateUrl: './comment-form.component.html',
    styleUrls: ['./comment-form.component.scss'],
    standalone: false
})
export class CommentFormComponent implements OnInit {
  @Input() comment_type: string;
  @Input() object_id: number;
  @Output() newComment = new EventEmitter<Comment>();

  @ViewChild('commentInput') commentInput: ElementRef;


  private objectid: string;
  commentForm: FormGroup;
  buttonText = 'Update';

  public showEmojiPicker: boolean = false;
  public comment: string = '';
  public emocats = [
    'people', 'nature'
  ]

  position: number;

  public emojiFallback = (emoji: any, props: any) => (emoji ? `:${emoji.shortNames[0]}:` : props.emoji);

  constructor(
    private fb: FormBuilder,
    private commentService: CommentService,
    private router: Router,
    private urlService: UrlService,
    private logger: NGXLogger,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.logger.debug('ngOnInit comment form');
    this.objectid = this.route.snapshot.params.id;
    // console.log(this.episodeid);
    this.commentForm = this.fb.group({
      // name: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
    });
    this.logger.debug('ngOnInit comment form comment_type : ' + this.comment_type);
    this.logger.debug('ngOnInit comment form end');
  }

  onCommentCancel() {
    this.commentForm.reset();
  }

  onSubmit() {
    this.logger.debug('ngOnSubmit comment form comment_type : ' + this.comment_type);
    const newC = new Comment();
    newC.text = this.commentForm.value.message;
    // newC.to_content_type = CommentType.COMMENT_TYPE_EPISODE;
    // newC.to_content_type = 19;
    // newC.to_object_id = parseInt(this.episodeid, 10);
    const apiUrlPath = this.urlService.getApiUrlPath()
    newC.to_content_object = `${apiUrlPath}${this.comment_type}s/${this.object_id}/`;
    // newC.to_content_object = parseInt(this.episodeid, 10);
    this.logger.debug('new comment:' + newC.text);
    // {
    //   name: this.commentForm.value.name,
    //     email: this.commentForm.value.email,
    //       message: this.commentForm.value.message,
    //         project: this.projectId,
    //           postDate: Date.now()
    // };
    this.commentService.addComment(newC).subscribe(
      data => {
        // console.log('added commentid: ' + data.id);
        console.log('emitting new comment: ' + data.id);
        // this.newComment.emit(newC)
        this.newComment.emit(data)
        this.commentForm.reset();
      },
      error => {
        console.log(error);
      });
  }
  onCancel() {
    this.router.navigate(['/home'], { relativeTo: this.route });
  }


  public addEmoji(event: { emoji: { native: any; }; }) {
    console.log('emoji :' + JSON.stringify(event.emoji))
    this.logger.debug('emoooo===' + event.emoji.native + '====');
    this.commentInput.nativeElement.focus();
    const selectionStart = this.commentInput.nativeElement.selectionStart;
    const currentValue = this.commentInput.nativeElement.value;
    const newValue = currentValue.substring(0, selectionStart) + event.emoji.native + currentValue.substring(selectionStart);
    this.commentForm.get('message').setValue(newValue);
    // the following 2 lines set the caret position behind the emoji
    this.commentInput.nativeElement.selectionStart = selectionStart + event.emoji.native.length;
    this.commentInput.nativeElement.selectionEnd = selectionStart + event.emoji.native.length;
    this.showEmojiPicker = false;
  }

  toggleEmojiPicker() {
    console.log(this.showEmojiPicker);
    this.showEmojiPicker = !this.showEmojiPicker;
    // this.position = getPos(this.commentInput.nativeElement);
    // this.logger.debug('positio :' + this.position)
  }



  onFocus() {
    console.log('focus');
    this.showEmojiPicker = false;
  }
  onBlur() {
    console.log('onblur')
  }

}



