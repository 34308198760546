import { AuthenticationService } from '@/_services';
import { AppConfigService } from '@/services/app-config.service';
import { NewstosubscriberService } from '@/services/newstosubscriber.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { NGXLogger } from 'ngx-logger';
import { take } from 'rxjs';
// inspired from https://blog.angular-university.io/angular-push-notifications/

@Component({
  selector: 'app-subscribe-to-notification',
  templateUrl: './subscribe-to-notification.component.html',
  styleUrl: './subscribe-to-notification.component.scss',
  standalone: false
})
export class SubscribeToNotificationComponent {
  // readonly VAPID_PUBLIC_KEY = "BM2vjwWQqhGbr5xO31";
  // readonly VAPID_PUBLIC_KEY = "BM2vjwWQqhGbr5xO31-f_SmXclyiMtQVXkGXooVb7mDZtc886g6k47SFkfe5JGn1QQoDS5uDbslG1VBryAqgzNI"
  // readonly keys = { "publicKey": "BM2vjwWQqhGbr5xO31-f_SmXclyiMtQVXkGXooVb7mDZtc886g6k47SFkfe5JGn1QQoDS5uDbslG1VBryAqgzNI", "privateKey": "e2kYgRG3LUDvFIPWdrdmZcBnCu-U3N2YBGfl0xlxnFQ" }

  constructor(
    private swPush: SwPush,
    private newstosubscriberService: NewstosubscriberService,
    private appconfigService: AppConfigService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private logger: NGXLogger) {
    if (this.authenticationService.currentUserValue) {
      this.logger.debug('current user : ' + this.authenticationService.currentUserValue);
    }
  }

  ngOnInit() {
  }

  subscribeToNotifications() {
    this.logger.debug('calling swpush subscribeToNotifications')
    this.logger.debug('vapid public key : ', this.appconfigService.config.VAPID_PUBLIC_KEY);
    this.swPush.requestSubscription({
      serverPublicKey: this.appconfigService.config.VAPID_PUBLIC_KEY
    })
      .then(sub => {
        this.logger.debug(JSON.stringify(sub));
        // "expirationTime": null,
        const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase();
        let sub1 = {
          "subscription": sub,
          "status_type": "subscribe",
          "browser": browser
        }
        // delete sub["expirationTime"];
        this.logger.debug('supbscribe  subscription1  ', JSON.stringify(sub1));
        this.newstosubscriberService.addPushSubscriber(sub1).subscribe()
        // let sub = {
        //   "subscription": {
        //     "endpoint": "https://fcm.googleapis.com/fcm/send/cpn4e3kTSZs:APA91bF_pOVjA5jNTydXEatRbLcfX_af82Pi4BVT7NS5frSwQiBeTgoL8WsjgRT04R-I225MsY-53YhV0jZkai4h4Cf5XlaB8QgAmLPXlu3NxCKdjrGiAg5EeVOvc0fHPRzLSw2lTQ0R",
        //     "keys": {
        //       "p256dh": "BK4m3RaVFA6npNQ-LqEWnF7hH0pHLkYla2Qj6X85QFtbGHke97D2ZYoCw5V6odCwF6n06vywCxrKecwcJcckE40",
        //       "auth": "c8P_LDGj3oPE4QyI4CrFmw"
        //     }
        //   },
        //   "status_type": "subscribe",
        //   "browser": browser
        // }
      })
      .catch(err => console.error("Could not subscribe to notifications", err));
  }

  unSubscribeToNotifications() {
    this.logger.debug('calling swpush unsubscribe');
    const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase();

    this.swPush.subscription
      .pipe(take(1)).subscribe(
        subscription => {
          if (subscription) {
            this.logger.debug('unsupbscribe curren subscription', subscription);
            let sub1 = {
              "subscription": subscription,
              "status_type": "unsubscribe",
              "browser": browser
            }
            subscription.unsubscribe();
            this.logger.debug('unsupbscribe  subscription', JSON.stringify(subscription));
            this.newstosubscriberService.removeSubscriber(sub1).subscribe();
          }
          else
            this.logger.debug('no subscription to unsupbscribe');
        })

    // this.swPush.unsubscribe()
    //   .then(sub => { this.logger.error('unsubscribe donie') })
    //   .catch(err => console.error("Could not unsubscribe to notifications", err));

    // let snackBarRef = this.snackBar.open('Now you are unsubscribed', null, {
    //   duration: this.snackBarDuration
    // });

  }

  cancel() {
    this.router.navigate(['/']);
  }

}
