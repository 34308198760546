import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { concat, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Serie } from '../models/serie';
import { SerieService } from '../services/serie.service';

@Component({
    selector: 'app-serie-search',
    templateUrl: './serie-search.component.html',
    styleUrls: ['./serie-search.component.scss'],
    standalone: false
})
export class SerieSearchComponent implements OnInit, AfterViewInit {
  public display = 'list'; // possible value list or grid
  series$: Observable<Serie[]>;
  private searchTerms = new Subject<string>();
  searchForm: FormGroup;

  @ViewChild('searchInput', { static: true }) input: ElementRef;

  constructor(private ref: ChangeDetectorRef, private formBuilder: FormBuilder, private serieService: SerieService,
    private logger: NGXLogger) { }

  // Push a search term into the observable stream.
  search(term: string): void {
    this.searchTerms.next(term);
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchTerm: ['', [Validators.required]],
    }
    );
    this.logger.debug('SearchComponent ngOnInit');
    this.series$ = this.loadSeries('ser');
    // this.series$ = this.searchTerms.pipe(
    //   // wait 300ms after each keystroke before considering the term
    //   debounceTime(300),

    //   // ignore new term if same as previous term
    //   distinctUntilChanged(),

    //   // switch to new search observable each time the term changes
    //   switchMap((term: string) => this.serieService.searchSeries(term)),
    // );
  }
  ngAfterViewInit() {

    const searchSeries$ = fromEvent<any>(this.input.nativeElement, 'keyup')
      .pipe(
        map(event => event.target.value),
        debounceTime(400),
        distinctUntilChanged(),
        tap(x => this.logger.debug(`found series matching "${x}"`)),
        switchMap(search => this.loadSeries(search as string)),
        tap(x => {
          this.ref.detectChanges();
        })
      );

    const initialSeries$ = this.loadSeries();

    this.series$ = concat(initialSeries$, searchSeries$);

  }

  loadSeries(search = ''): Observable<Serie[]> {
    return this.serieService.searchSeries(search);
  }
  onSubmit() {
  }
}
