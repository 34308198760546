import { FollowsService } from '../services/follows.service';
import { Author } from '@/models/author';
import { User } from '@/models/user';
import { Component, Input, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-followed',
    templateUrl: './followed.component.html',
    styleUrls: ['./followed.component.scss'],
    standalone: false
})
export class FollowedComponent {
  @ViewChild('tabgroup', { static: true }) tabgroup: MatTabGroup;
  @Input() title = 'My bookmarked episodes';
  @Input() displayMode = 'list'; // possible value list or grid
  @Input() selectedTabNum: number = 2;
  myfriends$: Observable<User[]>;
  myfollowed$: Observable<User[]>;
  myfollowers$: Observable<User[]>;

  constructor(private followsService: FollowsService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('tabid');
    this.selectedTabNum = id;
    console.log('followed component ngoninit');
    this.myfriends$ = this.getMyFriends();
    this.myfollowed$ = this.getMyFollowed();
    this.myfollowers$ = this.getMyFollowers();
  }
  getMyFriends(): Observable<User[]> {
    return this.followsService.getMyFriends();
  }
  getMyFollowed(): Observable<User[]> {
    return this.followsService.getMyFollowed();
  }
  getMyFollowers(): Observable<User[]> {
    return this.followsService.getMyFollowers();
  }

  changeSelectedTabNum(tabnum) {
    this.selectedTabNum = tabnum;
    this.router.navigate(['/myrelations/' + tabnum]);
  }
  // public selected: number;
  private swipecoord?: [number, number];
  private swipetime?: number;
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipecoord = coord;
      this.swipetime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipecoord[0], coord[1] - this.swipecoord[1]];
      const duration = time - this.swipetime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        switch (swipe) {
          case 'previous':
            if (this.selectedTabNum > 0) { this.selectedTabNum--; }
            break;
          case 'next':
            if (this.selectedTabNum < this.tabgroup._tabs.length - 1) { this.selectedTabNum++; }
            break;
        }
      }
    }
  }

}