import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Episode } from '../models/episode';
import { LikeService } from '../services/like.service';
import { FormControl, FormGroup } from '@angular/forms';
import { map, Observable, Subject } from 'rxjs';
import { Page } from '../pagination';
import { ImageService } from '@/services/image.service';
import { AuthenticationService } from '@/_services';
import { CommentService } from '@/services/comment.service';
import { CommentEpisodeService } from '@/services/commentepisode.service';
import { CommentPhotoService } from '@/services/commentphoto.service';
import { CommentSerieService } from '@/services/commentserie.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { EpisodeService } from '@/services/episode.service';
import { Serie } from '@/models/serie';
import { Photo } from '@/models/photo';
import { SerieService } from '@/services/serie.service';
import { PhotoService } from '@/services/photo.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
    selector: 'app-likes',
    templateUrl: './likes.component.html',
    styleUrls: ['./likes.component.scss'],
    standalone: false
})
export class LikesComponent implements OnInit {
  @ViewChild('tabgroup', { static: true }) tabgroup: MatTabGroup;
  @Input() owner_id: number;
  @Input() object_id: number;
  @Input() content_type: string;
  @Input() selectedTabNum: number = 2;
  series$: Observable<Serie[]>;
  episodes$: Observable<Episode[]>;
  photos$: Observable<Photo[]>;

  constructor(
    private serieService: SerieService,
    private episodeService: EpisodeService,
    private photoService: PhotoService,
    public authenticationService: AuthenticationService,
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const tabid = +this.route.snapshot.paramMap.get('tabid');
    if (tabid)
      this.selectedTabNum = tabid;
    else
      this.selectedTabNum = 0;
    this.series$ = this.getMyLikedSeries();
    this.episodes$ = this.getMyLikedEpisodes();
    this.photos$ = this.getMyLikedPhotos();
  }
  getMyLikedSeries(): Observable<Serie[]> {
    return this.serieService.getMyLikedSeries();
  }
  getMyLikedEpisodes(): Observable<Episode[]> {
    return this.episodeService.getMyLikedEpisodes();
  }

  getMyLikedPhotos(): Observable<Photo[]> {
    return this.photoService.getMyLikedPhotos();
  }

  changeSelectedTabNum(tabnum) {
    this.selectedTabNum = tabnum;
    this.router.navigate(['/myliked/' + tabnum]);
  }
  // public selected: number;
  private swipecoord?: [number, number];
  private swipetime?: number;
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipecoord = coord;
      this.swipetime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipecoord[0], coord[1] - this.swipecoord[1]];
      const duration = time - this.swipetime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        switch (swipe) {
          case 'previous':
            if (this.selectedTabNum > 0) { this.selectedTabNum--; }
            break;
          case 'next':
            if (this.selectedTabNum < this.tabgroup._tabs.length - 1) { this.selectedTabNum++; }
            break;
        }
      }
    }
  }
}
